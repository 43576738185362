import React from "react";
import { Container, Form, Col, Button, InputGroup } from "react-bootstrap";

import _ from "lodash";
import * as yup from "yup";
import { Formik } from "formik";
import styles from "src/marketStyle.module.scss";

import { marketDeliveryOption, lockerArea } from "src/types/typings";
import { useTranslation } from "react-i18next";
import ProductDetails from "./marketChkoutCart";
import {
  LayoutShippingMethod,
  LayoutLockerDist,
  LayoutShippingAddress,
  LayoutLockerArea,
  LayoutLockerType,
  LayoutPickupDetails,
  LayoutLockerLocation,
  LayoutInputAddress,
} from "./marketChkoutFormComp";

import { FormFields, MarketOptions } from "src/types/typings";

import OrderSummary from "./marketChkoutSummary";
import topicSlice from "src/redux/reducers/topicSlice";

//SECTION: SHIPPING DETAILS
const CheckoutForm = (props: {
  onClickSubmitOrder: any;
  marketOptions: Partial<MarketOptions>;
  topicID: string;
}) => {
  const onClickSubmitOrder = props.onClickSubmitOrder;
  const marketOptions = props.marketOptions;
  const topicID = props.topicID;

  console.log("form redender");
  const { t, i18n } = useTranslation();

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const schema = yup.object().shape({
    orderEmail: yup
      .string()
      .email(`${t("yup.invalidEmail")}`)
      .required(`${t("yup.required")}`),
    orderName: yup
      .string()
      .min(3, `${t("yup.name2short")}`)
      .max(50, `${t("yup.name2long")}`)
      .required(`${t("yup.required")}`),
    orderPhone: yup.string().matches(phoneRegExp, `${t("yup.invalidPhone")}`),
    orderAdditionalMsg: yup.string().max(50, `${t("yup.msg2long")}`),
  });

  let initialMktDeliveryOpt = marketDeliveryOption.NOTSET;
  if (
    marketOptions.mktDeliveryOption == marketDeliveryOption.COURIER ||
    marketOptions.mktDeliveryOption == marketDeliveryOption.PICKUP
  ) {
    initialMktDeliveryOpt = marketOptions.mktDeliveryOption;
  }

  return (
    <Container className="p-4">
      <Formik
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          console.log("***submit pressed", values);
          //setSubmitValues(values);
          onClickSubmitOrder(values);
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
        }}
        initialValues={{
          orderName: "",
          orderEmail: "",
          orderAdditionalMsg: "",
          orderAddress1: "",
          orderAddress2: "",
          orderPhone: "",
          orderEFLockerCode: "",
          orderCourierPayment: "",
          orderShippingAddress: 0, //0-undefined, 1-Locker, 2-Address
          orderDeliveryMethod: initialMktDeliveryOpt,
          orderLockerArea: lockerArea.NOTSET,
          orderDistID: "",
          orderLockerType: 0, //0-undefined, 1-EFLocker, 2-EFStore
          orderPickupDetails: "",
          orderLockerLocID: "",
          selOrderLockerDist: {},
          orderLockerDists: [],
          orderLockerLocs: [],
          orderCourierPaymentTxt: "",
          orderShippingFee: 0,
          orderFinalPrice: 0,
          orderProductPrice: 0,
          orderCur: "",
          orderMktIDs: [],
          orderSpecs: [],
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} onChange={handleChange}>
            <Form.Row className="login__title text-left mb-4">
              {t("market.chkoutBuyer")}
              <div className={`w-100 ${styles.titleBtnLine}`} />
            </Form.Row>
            <Form.Group controlId="name">
              <Form.Row>
                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Form.Label>{t("market.chkoutName")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="orderName"
                    placeholder={t("market.chkoutNamePlaceholder")}
                    value={values.orderName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.orderName}
                    isValid={touched.orderName && !errors.orderName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.orderName}
                  </Form.Control.Feedback>
                </Col>
              </Form.Row>
            </Form.Group>
            <Form.Group>
              <Form.Row>
                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Form.Group controlId="phone" className="numberInput">
                    <Form.Label>{t("market.chkoutPhone")}</Form.Label>
                    {/*https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp*/}
                    <Form.Control
                      name="orderPhone"
                      type="tel"
                      inputMode="tel"
                      pattern="[0-9]*"
                      onChange={handleChange}
                      value={values.orderPhone}
                      isInvalid={!!errors.orderPhone}
                      isValid={touched.orderPhone && !errors.orderPhone}
                      placeholder={t("market.chkoutPhonePlaceholder")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.orderPhone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Form.Group controlId="email">
                    <Form.Label>{t("market.chkoutEmail")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="orderEmail"
                      placeholder={t("market.chkoutEmailPlaceholder")}
                      value={values.orderEmail}
                      onChange={handleChange}
                      isInvalid={!!errors.orderEmail}
                      isValid={touched.orderEmail && !errors.orderEmail}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.orderEmail}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
            </Form.Group>

            {/*Shipping Method: Courier or Pickup*/}
            <LayoutShippingMethod
              mktDeliveryOpt={marketOptions.mktDeliveryOption}
            />
            {/*Pick-up Details*/}
            <LayoutPickupDetails mktPickupLocs={marketOptions.mktPickupLocs} />
            {/*ShippingAddress: Locker or address*/}
            <LayoutShippingAddress />
            {/*Locker Type*/}
            <LayoutLockerType />
            {/*Area*/}
            <LayoutLockerArea />
            {/*District*/}
            <LayoutLockerDist name="orderLockerDists" />
            {/*Location*/}
            <LayoutLockerLocation />
            {/*Address*/}
            <LayoutInputAddress />

            <Form.Row className="login__title text-left mb-4 mt-4">
              {t("market.chkoutOrders")}
              <div className={`w-100 ${styles.titleBtnLine}`} />
            </Form.Row>

            <Form.Row>
              <ProductDetails topicID={topicID} />
            </Form.Row>

            <Form.Row className="mt-4 mb-4">
              <InputGroup>
                <Form.Control
                  as="textarea"
                  placeholder={t("market.chkoutMsg")}
                  aria-label="With textarea"
                  name="orderAdditionalMsg"
                  value={values.orderAdditionalMsg}
                  onChange={handleChange}
                  isInvalid={!!errors.orderAdditionalMsg}
                  isValid={
                    touched.orderAdditionalMsg && !errors.orderAdditionalMsg
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.orderAdditionalMsg}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Row>

            <Form.Row className="login__title text-left mb-4 mt-4">
              {t("market.chkoutSummary")}
              <div className={`w-100 ${styles.titleBtnLine}`} />
            </Form.Row>
            <Form.Row>
              <OrderSummary marketOptions={marketOptions} />
            </Form.Row>

            <Form.Row>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="success"
                className={`w-100 ${styles.addCartBtn}`}
              >
                {t("market.chkoutSubmit")}
              </Button>
            </Form.Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default React.memo(CheckoutForm);
