/**
|--------------------------------------------------
| src/redux/reducers/photoSlice
|--------------------------------------------------
*/

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PhotoData, PhotoState } from "src/types/typings";
import { AppDispatch, AppState } from "src/redux/store";
import _ from "lodash";
import { fetchSinglePhotoFromFirebase } from "src/firebase/firebasePhotos";

const msgInitialState: PhotoState = {
  data: {},
  isLoading: false,
  currentRequestId: undefined,
  error: null,
};

interface PhotoInputs {
  topicID: string;
  photoID: string;
}

//Fetch one photo
export const fetchSinglePhotoThunk = createAsyncThunk<
  Partial<PhotoData>,
  PhotoInputs,
  {
    state: AppState;
    requestId: string;
  }
>(
  "photo/fetchSinglePhoto",
  async ({ topicID, photoID }, { getState, requestId }) => {
    const curState = getState();
    const { currentRequestId, isLoading } = curState.photos;
    const photoData = await fetchSinglePhotoFromFirebase(topicID, photoID);
    const val = photoData;

    const photo: Partial<PhotoData> = {
      photoID: photoID,
      photoCreationTimestamp: val.photoCreationTimestamp as number,
      photoCaption: val.photoCaption as string,
      photoContentURL: val.photoUrl as string,
      photoCreatorID: val.photoCreatorID as string,
      photoThumbnailURL: val.thumbnail as string,
      photoContentSize: val.photoContentSize as number,
      photoIsTopicContent:
        val.isChat === undefined ? true : (!val.isChat as boolean),
    };

    return photo;
  },
  {
    condition: ({ topicID, photoID }, { getState, extra }) => {
      const rootState = getState() as AppState;
      const targetPhoto = rootState.photos.data.photoID;
      console.log(
        "condition for target photo",
        targetPhoto,
        "photoID",
        photoID
      );
      if (targetPhoto) {
        console.log("photo already loaded", photoID);
        return false;
      } else {
        console.log("continue loading photo", photoID);
      }
    },
  }
);

export const photoSlice = createSlice({
  name: "msg",
  initialState: msgInitialState,
  reducers: {
    fetchPhotoSuccess(state, action: PayloadAction<Partial<PhotoData>>) {
      const payload = action.payload;
      console.log("chatVC success getting payload", payload);
      const photoID = payload.photoID as string;
      state.data[photoID] = Object.assign({}, state.data[photoID], payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSinglePhotoThunk.pending, (state, action) => {
      //console.log("fetchGroupCredentialsThunk pending");
      state.isLoading = true;
      state.currentRequestId = action.meta.requestId;
      const photoID = action.meta.arg.photoID as string;
    });
    builder.addCase(fetchSinglePhotoThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      const photoID = payload.photoID as string;

      if (payload && !_.isEmpty(payload)) {
        console.log("fetchSinglePhotoThunk fulfilled");
        state.data[photoID] = Object.assign({}, state.data[photoID], payload);
        state.isLoading = false;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchSinglePhotoThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.currentRequestId = undefined;
      console.log("fetchSinglePhotoThunk rejected");
    });
  },
});

export const { fetchPhotoSuccess } = photoSlice.actions;
export default photoSlice.reducer;
