/**
|--------------------------------------------------
| src/redux/reducers/marketSlice
|--------------------------------------------------
*/

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MarketData,
  MarketSpecT2,
  MarketSpecT1,
  MarketSpec,
  MarketState,
  PhotoData,
  MarketOptions,
  marketShippingFeeOption,
  marketDeliveryOption,
  LockerDistData,
  LockerLocData,
  MarketContactMethod,
  MarketPaymentMethod,
  MarketOrder,
  MarketOrderSpec,
} from "src/types/typings";
import { fetchGroupCredentials } from "src/firebase/firebaseGroups";
import { AppDispatch, AppState } from "src/redux/store";
import { fetchPhotoSuccess } from "src/redux/reducers/photoSlice";
import {
  fireFetchSingleTopicMarketDetails,
  fireFetchTopicMarkets,
  fireFetchTopicMarketOptions,
} from "src/firebase/firebaseMarkets";
import {
  mapMarketSpec,
  fetchMarketSpecSuccess,
} from "src/redux/reducers/marketSpecSlice";
import _ from "lodash";
import { string } from "yup/lib/locale";
import { FiAlignLeft } from "react-icons/fi";
import TopicDetails from "src/components/topics/topicDetails";

const marketsInitialState: MarketState = {
  data: {},
  isLoading: false,
  currentRequestId: undefined,
  error: null,
};

export const fetchTopicMarketsThunk = createAsyncThunk<
  //Record<string, Partial<MarketData>>,
  Partial<MarketData>[],
  string,
  {
    state: AppState;
    requestId: string;
  }
>("markets/fetchTopicMarkets", async (topicID, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  console.log("marketSlice reached");
  const marketDatas = await fireFetchTopicMarkets(topicID);
  let tmpMarkets: Partial<MarketData>[] = [];
  if (marketDatas && !_.isEmpty(marketDatas)) {
    const marketIDs = Object.keys(marketDatas);
    marketIDs.map(async (marketID) => {
      const marketData = marketDatas[marketID] as Record<string, any>;
      const market = mapMarket(topicID, marketID, marketData);

      if (market.marketPhotos && !_.isEmpty(market.marketPhotos)) {
        console.log("has marketPhotos");
        const mPhotos = market.marketPhotos;
        const photoIDs = Object.keys(mPhotos);
        photoIDs.map(async (photoID) => {
          const photoData = mPhotos[photoID] as Record<string, any>;
          const photo = mapMarketPhoto(photoID, marketID, photoData);
          if (photo.photoOrder == 0 && photo.photoContentURL) {
            console.log("marketDisplayPhotoURL", photo.photoContentURL);
            market.marketDisplayPhotoURL = photo.photoContentURL;
            market.marketDisplayPhoto = photo;
          }
          dispatch(fetchPhotoSuccess(photo));
        });
      } else {
        console.log("no market photos");
      }

      //MarketSpecT1, MarketSpecT2 and marketSpecT1sCln
      if (market.marketSpecT1 && !_.isEmpty(market.marketSpecT1)) {
        const specsT1 = market.marketSpecT1;
        const specT1IDs = Object.keys(specsT1);
        let specT1s: MarketSpecT1[] = [];
        specT1IDs.map((specT1ID) => {
          const specT1Data = specsT1[specT1ID] as Record<string, any>;
          const specTitle = specT1Data.specTitle;
          const specT2Datas = specT1Data.mktSpecT2;
          const specT2IDs = Object.keys(specT2Datas);

          let specT2s: MarketSpecT2[] = [];
          specT2IDs.map((specT2ID) => {
            const specT2Data = specT2Datas[specT2ID];
            const specT2Detail = specT2Data.specDetail;
            const specT2 = mapMarketSpecT2(
              specT2ID,
              specT1ID,
              specTitle,
              specT2Detail
            );
            specT2s.push(specT2);
          });
          const specT1 = mapMarketSpecT1(specT1ID, specTitle, specT2s);
          specT1s.push(specT1);
        });
        market.marketSpecT1sCln = specT1s;
      }

      //MarketSpec and marketSpecsCln
      console.log("YYY market is", market);
      if (market.marketSpecs && !_.isEmpty(market.marketSpecs)) {
        const specs = market.marketSpecs;
        const specIDs = Object.keys(specs);
        //let specsCln: Partial<MarketSpec>[] = [];
        specIDs.map((specID) => {
          const specData = specs[specID] as Record<string, any>;
          const spec = mapMarketSpec(specID, topicID, marketID, specData);
          //Update store
          dispatch(fetchMarketSpecSuccess(spec));
          //specsCln.push(spec);
        });

        //market.marketSpecsCln = specsCln;
      }

      tmpMarkets.push(market);
    });
  }

  //   let tmpMarkets: Partial<MarketData>[] = [];
  //   console.log("tmpMarket is 1", tmpMarkets);

  //   if (marketDatas && !_.isEmpty(marketDatas)) {
  //     marketDatas.map((marketData) => {
  //       if (marketData && !_.isEmpty(marketData)) {
  //         const market = mapMarket(topicID, marketData);
  //         const marketID = market.marketID;
  //         if (marketID) {
  //           tmpMarkets.push(market);
  //         }
  //       }
  //     });
  //   }
  //   console.log("tmpMarket is 2", tmpMarkets);
  return tmpMarkets;
});

export const fetchSingleTopicMarketThunk = createAsyncThunk<
  //return type
  Partial<MarketData>,
  //first argument (combine as object if have multiple args)
  { topicID: string; marketID: string },
  //thunk API payload creator
  { state: AppState; requestId: string }
>(
  "markets/fetchSingleTopicMarketDetails",
  async ({ topicID, marketID }, thunkAPI) => {
    //const { topicID, marketID } = topicMarketIDs;
    //const curState = thunkAPI.getState();
    //const curState = getState();
    //const { currentRequestId, isLoading } = curState.markets;
    let market: Partial<MarketData> = {};
    const val = await fireFetchSingleTopicMarketDetails(topicID, marketID);
    if (val && !_.isEmpty(val)) {
      market = mapMarket(topicID, marketID, val);
    }
    console.log(
      "<fetchSingleTopicMarketThunk> finished mapping Market",
      marketID,
      market
    );
    return market;
  }
);

const mapMarket = (
  topicID: string,
  marketID: string,
  val: Record<string, any>
) => {
  const market: Partial<MarketData> = {
    marketID: marketID,
    marketTitle: val.mktTitle,
    marketDesc: val.mktDesc,
    topicID: topicID,
    marketCurrency: val.mktCurrency,
    marketCategoryT1: val.mktCatT1,
    marketCategoryT2: val.mktCatT2,
    marketDisplayPrice: val.mktDisplayPrice,
    marketDisplayOriPrice: val.mktDisplayOriPrice,
    marketOrderLimit: val.mktOrderLimit,
    marketCondition: val.mktCondition,
    marketCreationTS: val.mktCreationTimestamp,
    marketLastTS: val.mktLastTimestamp,
    marketOrder: val.mktOrder,
    marketPhotos: val.mktPhotos,
    marketSpecs: val.mktSpecs,
    marketSpecT1: val.mktSpecT1,
    marketTags: val.mktTags,
  };
  return market;
};

const mapMarketSpecT2 = (
  specT2ID: string,
  specT1ID: string,
  specTitle: string,
  specDetail: string
) => {
  const specT2: MarketSpecT2 = {
    specT2ID: specT2ID,
    specT1ID: specT1ID,
    specTitle: specTitle,
    specDetail: specDetail,
  };
  return specT2;
};

const mapMarketSpecT1 = (
  specT1ID: string,
  specTitle: string,
  specT2s: MarketSpecT2[]
) => {
  const specT1: MarketSpecT1 = {
    specT1ID: specT1ID,
    specTitle: specTitle,
    specT2s: specT2s,
  };
  return specT1;
};

const mapMarketPhoto = (
  photoID: string,
  marketID: string,
  val: Record<string, any>
) => {
  const photo: Partial<PhotoData> = {
    photoID: photoID,
    photoCreationTimestamp: val.photoCreationTimestamp,
    photoCreatorID: val.photoCreatorID,
    photoOrder: val.photoOrder,
    photoContentURL: val.photoUrl,
    photoMarketID: marketID,
  };
  return photo;
};

export const mapLockerDistData = (distID: string, val: Record<string, any>) => {
  const dist: Partial<LockerDistData> = {
    distID: distID,
    distLocs: val.codes,
    distName: val.district,
    distNameCT: val.districtct,
    isStore: val.isstore,
    lastTS: val.lastTS,
  };
  return dist;
};

export const mapLockerLocData = (locID: string, val: Record<string, any>) => {
  const loc: Partial<LockerLocData> = {
    locID: locID,
    address: val.address,
    addressCT: val.addressct,
    area: val.area,
    districtCT: val.districtct,
    district: val.district,
    hourmo2st: val.hourmo2st,
    hoursu: val.hoursu,
    isHot: val.ishot,
    isStore: val.isstore,
    lastTS: val.lastTS,
  };
  return loc;
};

export const mapMarketOrder = (
  topicID: string,
  topicLinkID: string,
  orderID: string,
  val: Record<string, any>
) => {
  console.log("begin mapMarketOrder");
  let res: Partial<MarketOrder> = {
    orderID: orderID,
    orderName: val.orderName,
    orderUserID: val.orderUserID,
    orderAddress1: val.orderAddress1,
    orderAddress2: val.orderAddress2,
    orderPhone: val.orderPhone,
    orderEmail: val.orderEmail,
    orderEFLockerCode: val.orderEFLockerCode,
    orderCurrency: val.orderCurrency,
    orderDeliveryMethod: val.orderDeliveryMethod,
    orderPickupDetails: val.orderPickup,
    orderAdditionalMsg: val.orderMsg,
    orderPaymentStatus: val.orderPaymentStatus,
    orderStatus: val.orderStatus,
    orderProductPrice: val.orderPrice,
    orderShippingFee: val.orderShipPrice,
    orderFinalPrice: val.orderFinalPrice,
    orderTS: val.orderTimestamp,
    orderLastTS: val.orderLastTimestamp,
    orderCourierPayment: val.orderCourierPayment,
    isOrderPlaced: true,
    orderPaymentTS: val.orderPaymentTS ?? 0,
    orderUID: val.orderUID,
    topicID: topicID,
    topicLinkID: topicLinkID,
    orderCourierCompany: val.orderCourier,
    orderTrackingNum: val.orderTracking,
    orderSellerDLink: val.orderSellerDLink,
  };

  console.log("begin mapMarketOrder 2");

  //orderPaymentMethod
  console.log("<paymentMethodData 0>", val.orderPaymentMethod);
  if (val.orderPaymentID && val.orderPaymentMethod) {
    const paymentMethodID = val.orderPaymentID;
    if (val.orderPaymentMethod[paymentMethodID]) {
      const paymentMethodData = val.orderPaymentMethod[paymentMethodID];
      console.log("<paymentMethodData 1>", paymentMethodData);
      const mktPaymentMethod = mapMarketPaymentMethod(
        paymentMethodID,
        paymentMethodData
      );
      res.orderPaymentMethod = mktPaymentMethod;
    }
  }

  console.log("begin mapMarketOrder 3");
  //orderPaymentReceiptPhoto
  if (val.orderPaymentPhoto && val.orderReceipt) {
    const receiptPhotoID = val.orderPaymentPhoto;
    if (val.orderReceipt[receiptPhotoID]) {
      const receiptPhotoData = val.orderReceipt[receiptPhotoID];
      const receiptPhoto = mapContactPhoto(receiptPhotoID, receiptPhotoData);
      res.orderPaymentReceiptPhoto = receiptPhoto;
    }
  }

  console.log("begin mapMarketOrder 4");

  if (val.orderMktIDs) {
    const orderMktIDs = Object.keys(val.orderMktIDs);
    res.marketIDs = orderMktIDs;
  }

  console.log("begin mapMarketOrder 5");

  let res_orderSpecs: MarketOrderSpec[] = [];
  if (val.orderSpecs) {
    const orderSpecs = val.orderSpecs;
    const orderSpecIDs = Object.keys(orderSpecs);
    orderSpecIDs.map((specOrderID) => {
      const orderSpecData = orderSpecs[specOrderID];
      const orderSpec = mapOrderSpec(
        topicID,
        orderID,
        specOrderID,
        orderSpecData
      );
      return res_orderSpecs.push(orderSpec);
    });
  }
  res.orderSpecs = res_orderSpecs;

  console.log("returning market order data", res);
  return res;
};

const mapOrderSpec = (
  topicID: string,
  orderID: string,
  specOrderID: string,
  val: Record<string, any>
) => {
  let orderSpec: MarketOrderSpec = {
    specID: val.specID,
    specMktTitle: val.specMktTitle,
    specMktID: val.specMktID,
    specDesc: val.specDesc,
    specPrice: val.specPrice,
    specCurrency: val.specCurrency,
    specStock: val.specStock,
    specQuantity: val.specQuantity,
    specOrderID: specOrderID,
    orderID: orderID,
    specOrderLimit: val.specOrderLimit,
    specTopicID: topicID,
    specPhoto: {},
    specT2IDs: [],
  };

  if (val.specPhoto) {
    const photoData = val.specPhoto;
    const photoIDs = Object.keys(photoData);
    const photoID = photoIDs[0]; //There's one photo per contact/payment method for now
    const photoDetails = photoData[photoID];
    const specPhoto = mapContactPhoto(photoID, photoDetails);
    orderSpec.specPhoto = specPhoto;
  }

  if (val.specT2s) {
    const specT2s = Object.keys(val.specT2s);
    orderSpec.specT2IDs = specT2s;
  }

  return orderSpec;
};

export const mapMarketOptions = (topicID: string, val: Record<string, any>) => {
  console.log("mapMarketOptions Start");
  let mktDeliveryOption = marketDeliveryOption.NOTSET;
  const shippingFeeOption =
    val.mktShipping ??
    (marketShippingFeeOption.NOTSET as marketShippingFeeOption);
  const mktPickupLocs = val.mktPickupLocs ?? ({} as Record<string, string>);

  console.log(
    "***shippingFeeOption is",
    val,
    shippingFeeOption,
    Object.keys(mktPickupLocs).length
  );
  if (shippingFeeOption == marketShippingFeeOption.NOTSET) {
    //No shipping Fee
    if (mktPickupLocs && Object.keys(mktPickupLocs).length > 0) {
      //have pickup
      mktDeliveryOption = marketDeliveryOption.PICKUP;
    }
  } else {
    if (mktPickupLocs && Object.keys(mktPickupLocs).length > 0) {
      mktDeliveryOption = marketDeliveryOption.BOTH;
    } else {
      mktDeliveryOption = marketDeliveryOption.COURIER;
    }
  }

  console.log(`***mktDeliveryOption`, mktDeliveryOption);

  const contactDatas = val.mktContact;
  const paymentDatas = val.mktPayment;

  const mktContacts: MarketContactMethod[] = [];
  const mktPayments: MarketPaymentMethod[] = [];

  if (paymentDatas) {
    const paymentIDs = Object.keys(paymentDatas);
    paymentIDs.map((paymentID) => {
      const paymentDetails = paymentDatas[paymentID];
      const payment = mapMarketPaymentMethod(paymentID, paymentDetails);
      if (!mktPayments.includes(payment)) {
        mktPayments.push(payment);
      }
    });
  }

  if (contactDatas) {
    const contactIDs = Object.keys(contactDatas);
    contactIDs.map((contactID) => {
      const contactDetails = contactDatas[contactID];
      const contact = mapMarketContactMethod(contactID, contactDetails);

      if (!mktContacts.includes(contact)) {
        mktContacts.push(contact);
      }
    });
  }

  const res: Partial<MarketOptions> = {
    topicID: topicID,
    mktShipping: val.mktShipping,
    mktDeliveryOption: mktDeliveryOption,
    mktShippingFee: val.mktShippingFee,
    mktMinFreeShippingFee: val.mktMinFreeShippingFee,
    mktExpiryTimestamp: val.mktExpiryTimestamp,
    mktIsAddress: val.mktIsAddress,
    mktIsEmail: val.mktIsEmail,
    mktIsName: val.mktIsName,
    mktIsPhone: val.mktIsPhone,
    mktPaymentDetails: val.mktPaymentDetails,
    mktContactDetails: val.mktContactDetails,
    mktOptCreationTimestamp: val.mktOptCreationTimestamp,
    mktOptLastTimestamp: val.mktOptLastTimestamp,
    mktPickupLocs: val.mktPickupLocs,
    mktContacts: mktContacts,
    mktPayments: mktPayments,
    mktIsEFLocker: val.mktIsEFLocker,
  };

  console.log(`***1 res`, res);

  return res;
};

const mapMarketPaymentMethod = (
  paymentID: string,
  val: Record<string, any>
) => {
  const photoData = val.paymentPhoto;

  let paymentPhoto = {};
  if (photoData) {
    const photoIDs = Object.keys(photoData);
    const photoID = photoIDs[0]; //There's one photo per contact/payment method for now
    const photoDetails = photoData[photoID];
    paymentPhoto = mapContactPhoto(photoID, photoDetails);
  }

  const payment: MarketPaymentMethod = {
    paymentID: paymentID,
    paymentTitle: val.paymentTitle,
    paymentLink: val.paymentLink,
    paymentPhoto: paymentPhoto,
  };

  return payment;
};

const mapMarketContactMethod = (
  contactID: string,
  val: Record<string, any>
) => {
  const photoData = val.contactPhoto;

  let contactPhoto = {};
  if (photoData) {
    const photoIDs = Object.keys(photoData);
    const photoID = photoIDs[0]; //There's one photo per contact/payment method for now
    const photoDetails = photoData[photoID];
    contactPhoto = mapContactPhoto(photoID, photoDetails);
  }

  const contact: MarketContactMethod = {
    contactID: contactID,
    contactTitle: val.contactTitle,
    contactLink: val.contactLink,
    contactPhoto: contactPhoto,
  };

  return contact;
};

const mapContactPhoto = (photoID: string, val: Record<string, any>) => {
  const photo: Partial<PhotoData> = {
    photoID: photoID,
    photoContentSize: val.photoContentSize,
    photoCreationTimestamp: val.photoCreationTimestamp,
    photoCreatorID: val.photoCreatorID,
    photoContentURL: val.photoUrl,
  };
  return photo;
};

export const marketSlice = createSlice({
  name: "markets",
  initialState: marketsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSingleTopicMarketThunk.pending, (state, action) => {
      state.isLoading = true;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(fetchSingleTopicMarketThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      const marketID = payload.marketID as string;
      if (payload && !_.isEmpty(payload)) {
        state.data[marketID] = Object.assign({}, state.data[marketID], payload);
        state.isLoading = false;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchSingleTopicMarketThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.currentRequestId = undefined;
    });
    builder.addCase(fetchTopicMarketsThunk.pending, (state, action) => {
      state.isLoading = true;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(fetchTopicMarketsThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload && !_.isEmpty(payload)) {
        payload.map((marketData) => {
          const marketID = marketData.marketID as string;
          state.data[marketID] = Object.assign(
            {},
            state.data[marketID],
            marketData
          );
        });
        state.isLoading = false;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchTopicMarketsThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.currentRequestId = undefined;
    });
  },
});

export default marketSlice.reducer;
