import React from "react";
import { Container, Spinner } from "react-bootstrap";
//class App extends Component {

const LoadingScreen = () => {
  return (
    <Container className="d-flex justify-content-center vertical-center">
      <div className="spinner__box roundedCorners10 p-4">
        <div className="d-flex justify-content-center w-100">
          <Spinner animation="grow" variant="primary" />
        </div>
        <div className="d-flex spinner__loading justify-content-center w-100 mt-2">
          Loading...
        </div>
      </div>
    </Container>
  );
};

export default LoadingScreen;
