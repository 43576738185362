import React, { useEffect, useState } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { run as runHolder } from "holderjs/holder";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Image,
  Card,
  Navbar,
  Nav,
  ListGroup,
  Alert,
} from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { MakeSelectWithTopicLinkID } from "src/components/topics/topicDetails";

import { useTranslation } from "react-i18next";
import { TopicData, MarketData, MarketSpec } from "src/types/typings";
import _ from "lodash";
import styles from "src/marketStyle.module.scss";
import {
  IoRemove,
  IoAdd,
  IoTrashOutline,
  IoBagCheckOutline,
} from "react-icons/io5";

import { useSelector } from "react-redux";
import { AppState, useAppDispatch } from "src/redux/store";
import {
  selectCartSpecs,
  selectMarket,
  selectMktTotal,
} from "src/selectors/marketSelector";
import { selectTopicWithTopicID } from "src/selectors/topicSelector";
import { fetchMarketSpecSuccess } from "src/redux/reducers/marketSpecSlice";
import SignInScreen from "src/firebase/firebaseui";
import ShowNavBar from "src/components/navBar";
import { SRLWrapper } from "simple-react-lightbox";

export const MakeSelectCart = (topicID: string) => {
  const selSpecsRecords: Record<string, MarketSpec[]> = useSelector(
    (state: AppState) => {
      return selectCartSpecs(state, topicID);
    }
  );
  return selSpecsRecords;
};

export const makeSelectMarket = (marketID: string) => {
  const selMarket: MarketData = useSelector((state: AppState) => {
    return selectMarket(state, marketID);
  });
  return selMarket;
};

export const MakeSelectTopicWithTopicID = ({ topicID }) => {
  const selTopic: TopicData = useSelector((state: AppState) => {
    return selectTopicWithTopicID(state, topicID);
  });
  return selTopic;
};

export const MakeSelectMarketTotal = ({ topicID }) => {
  const mktTotal = useSelector((state: AppState) => {
    return selectMktTotal(state, topicID);
  });
  return mktTotal;
};

//SECTION: MAIN FUNCTION
const MarketCart = () => {
  const appDispatch = useAppDispatch();

  const [isDeleteSpecModalShown, setIsDeleteSpecModalShown] = useState(false);
  const [isSigninModalShown, setIsSigninModalShown] = useState(false);
  const [focusSpec, setFocusSpec] = useState<Partial<MarketSpec>>();
  const [counterAlertShow, setCounterAlertShow] = useState(false);

  const handleDeleteSpecModalClose = () => setIsDeleteSpecModalShown(false);
  const handleSigninModalClose = () => setIsSigninModalShown(false);

  const { topicLinkID } = useParams();

  const isLoggedIn = useSelector((state: AppState) => {
    return state.auth.isLoggedIn;
  });

  const { t, i18n } = useTranslation();

  const lightboxOptions = {
    settings: {
      lightboxTransitionSpeed: 0.1,
    },
    buttons: {
      showThumbnailsButton: false,
      showAutoplayButton: false,
      showDownloadButton: false,
    },
    thumbnails: {
      showThumbnails: true,
    },
  };
  const topic = MakeSelectWithTopicLinkID({ topicLinkID });

  useEffect(() => {
    //placeholder
    runHolder("image-class-name");
  }, []);

  const ShowDeleteSpecModal = () => {
    return (
      <Modal
        show={isDeleteSpecModalShown}
        centered={true}
        animation={true}
        onHide={handleDeleteSpecModalClose}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              ...styles,
              fontSize: 24,
              fontWeight: "bolder",
            }}
          >
            {t("market.cartDeleteTitle")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{t("market.cartDeleteContent")}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            block={true}
            variant="light"
            className={`${styles.statusBtn}`}
            onClick={handleDeleteSpecModalClose}
          >
            {t("market.cartDeleteCancel")}
          </Button>
          <Button
            block={true}
            variant="danger"
            className={`${styles.statusBtn}`}
            onClick={handleDeleteCartSpec}
          >
            {t("market.cartDeleteRemove")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ShowSigninModal = () => {
    return (
      <Modal
        show={isSigninModalShown}
        centered={true}
        animation={true}
        onHide={handleSigninModalClose}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              ...styles,
              fontSize: 24,
              fontWeight: "bolder",
            }}
          >
            Sign-in
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SignInScreen onHide={handleSigninModalClose} />
        </Modal.Body>
      </Modal>
    );
  };

  // const ShowNavBar = () => {
  //   return (
  //     <Navbar variant="light" bg="whiteShadow" sticky="top">
  //       <Navbar.Brand href="/">
  //         <img
  //           src={KalinkLogo}
  //           width="2rem"
  //           height="2rem"
  //           className="d-inline-block align-center bg-white"
  //           alt="Kalink"
  //         />
  //       </Navbar.Brand>
  //       <Nav className="mr-auto">
  //         <Nav.Link href="/">Home</Nav.Link>
  //       </Nav>
  //       <div className="mr-sm-2">
  //         <Button variant="success" onClick={handleGo2Checkout}>
  //           <IoBagCheckOutline
  //             size={24}
  //             className={`${styles.marketCartBtn} mb-1`}
  //           />
  //           <span className=".success ml-2">Checkout</span>
  //         </Button>
  //       </div>
  //     </Navbar>
  //   );
  // };

  const handleDeleteCartSpec = () => {
    //to delete a spec, spec quan is set to 0
    const newSpec: Partial<MarketSpec> = {};
    if (focusSpec) {
      newSpec.specID = focusSpec.specID;
      newSpec.orderQuantity = 0;
    }

    appDispatch(fetchMarketSpecSuccess(newSpec));
    handleDeleteSpecModalClose();
  };

  const ShowCounterAlert = () => {
    if (counterAlertShow) {
      return (
        <Alert
          variant="danger"
          onClose={() => setCounterAlertShow(false)}
          dismissible
        >
          <Alert.Heading>Alert!</Alert.Heading>
          <p>Maxium Amount Reached!</p>
        </Alert>
      );
    } else {
      return <></>;
    }
  };

  //({ selSpec, market })ß
  //{ selSpec: MarketSpec, market: MarketData }
  const ShowSpec = ({ selSpec, market }) => {
    const [orderQuan, setOrderQuan] = useState(1);

    useEffect(() => {
      // const newSubTotal = orderQuan * selSpec.specPrice;
      // const res = {};ß
      // res[selSpec.specID] = newSubTotal;
      // setSubTotal(res);
      if (selSpec.orderQuantity) {
        setOrderQuan(selSpec.orderQuantity);
      }
    }, [selSpec.orderQuantity, counterAlertShow]);

    const onClickDeleteCartSpec = () => {
      setIsDeleteSpecModalShown(true);
      setFocusSpec(selSpec);
    };

    //NOTE: WIP
    const onClickQuanUp = () => {
      if (orderQuan) {
        if (selSpec) {
          //TODO: Alerts
          //setSpecAlertShow(false);
          const newQuan = orderQuan + 1;

          console.log("selSpec.specStock", selSpec);

          if (
            selSpec &&
            selSpec.specStock &&
            selSpec.specStock > 0 &&
            newQuan > selSpec.specStock
          ) {
            setCounterAlertShow(true);
            return;
          }

          if (
            market.marketOrderLimit &&
            market.marketOrderLimit > 0 &&
            newQuan > market.marketOrderLimit
          ) {
            setCounterAlertShow(true);
          } else {
            //All validation passed, update store and state
            //selSpec.orderQuantity = newQuan;
            const newSpec: Partial<MarketSpec> = {};
            newSpec.specID = selSpec.specID;
            newSpec.orderQuantity = newQuan;
            appDispatch(fetchMarketSpecSuccess(newSpec));
            //setOrderQuan(newQuan);
          }
        } else {
          //setSpecAlertShow(true);
        }
      }
    };

    const onClickQuanDown = () => {
      if (orderQuan) {
        if (selSpec) {
          //setSpecAlertShow(false);
          const newQuan = orderQuan - 1;

          if (newQuan >= 1) {
            //All validation passed, update store and state
            //selSpec.orderQuantity = newQuan;
            const newSpec: Partial<MarketSpec> = {};
            newSpec.specID = selSpec.specID;
            newSpec.orderQuantity = newQuan;
            appDispatch(fetchMarketSpecSuccess(newSpec));
            //setOrderQuan(newQuan);
          }
        } else {
          //setSpecAlertShow(true);
        }
      }
    };

    const showInputQuantity = () => {
      return (
        <Col
          xs={10}
          sm={10}
          md={4}
          lg={4}
          xl={4}
          className={`${styles.cartInputGroup}`}
        >
          <div className="card">
            <InputGroup>
              {/*Decrement*/}
              <InputGroup.Prepend>
                <Button variant="light" onClick={onClickQuanDown}>
                  <IoRemove className={styles.inputControlBtn} />
                </Button>
              </InputGroup.Prepend>

              {/* TODO: onChange */}
              <FormControl
                className="text-center border-0 font-weight-bolder"
                value={orderQuan}
                readOnly={true}
              />

              {/*Increment*/}
              <InputGroup.Append>
                <Button variant="light" onClick={onClickQuanUp}>
                  <IoAdd className={styles.inputControlBtn} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </Col>
      );
    };

    const ShowSpecPhoto = () => {
      console.log("***spec1", selSpec.specID);
      if (selSpec.specPhoto && selSpec.specPhoto.photoContentURL != "") {
        console.log("***spec2", selSpec.specPhoto.photoContentURL);
        return (
          <a href={selSpec.specPhoto.photoContentURL}>
            <Image
              src={selSpec.specPhoto.photoContentURL}
              className={`d-inline-block align-center bg-white image-class-name`}
            />
          </a>
          //</SRLWrapper>
        );
      } else if (
        //Shouldn't fall into this case as draftPhoto already got inserted into 1 spec only cases
        //This is only a safeguard measure
        market.marketDisplayPhotoURL &&
        market.marketDisplayPhotoURL != ""
      ) {
        return (
          <Image
            src={market.marketDisplayPhotoURL}
            className={`d-inline-block align-center bg-white`}
          />
        );
      } else {
        //No photo
        return (
          <Image
            src="holder.js/200x200?text=Empty"
            className={`d-inline-block align-center bg-white image-class-name`}
            alt="Photo Not Found"
          />
        );
      }
    };

    return (
      <ListGroup.Item>
        <Row xs={12} sm={12} md={12} lg={12} xl={12}>
          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
            {ShowSpecPhoto()}
          </Col>
          <Col xs={9} sm={9} md={9} lg={9} xl={9}>
            <Row className="h-100">
              <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                <Row className={`${styles.cartSpecTitle}`}>
                  {market.marketTitle ?? "No Title"}
                </Row>
                <Row className={`${styles.cartSpecDesc}`}>
                  {selSpec.specDesc}
                </Row>
                <Row className={`mt-2 ${styles.cartSpecPrice}`}>
                  {market.marketCurrency} {selSpec.specPrice}
                </Row>
              </Col>

              {/* InputNumber for quantity */}
              {showInputQuantity()}

              <Col
                xs={2}
                sm={2}
                md={1}
                lg={1}
                xl={1}
                className={`card ${styles.cartInputGroup}`}
              >
                <Button
                  variant="light-outline"
                  className={styles.cartDeleteBtn}
                  onClick={onClickDeleteCartSpec}
                >
                  <IoTrashOutline size={20} />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  const ShowTopicHeader = (topicID: string) => {
    const topic = MakeSelectTopicWithTopicID({ topicID: topicID });
    const tot = MakeSelectMarketTotal({ topicID: topicID });
    const totString =
      `${t("market.total")}: ` + tot["cur"] + " " + tot["totPrice"];
    return (
      <Card.Header className={`d-md-flex justify-content-md-between`}>
        <div className={`${styles.marketTitle}`}>
          {t("market.title")}: {topic.topicTitle}
        </div>

        <div className={`${styles.marketTitle}`}>{totString}</div>
      </Card.Header>
    );
  };

  const ShowTopicContent = ({ selTopicSpecs }) => {
    return selTopicSpecs.map((spec) => {
      const market = makeSelectMarket(spec.specMarketID);
      //return <ShowSpec selSpec={spec} market={market} />;
      return <ShowSpec selSpec={spec} market={market} key={spec.specID} />;
    });
  };

  const ShowOrderSpecs = (selSpecs: Record<string, MarketSpec[]>) => {
    const selTopicIDs = Object.keys(selSpecs);
    return selTopicIDs.map((topicID) => {
      const selTopicSpecs = selSpecs[topicID];
      return (
        <Card className={`bg-light ${styles.cartContainer}`} key={topicID}>
          <ListGroup variant="flush">
            {ShowTopicHeader(topicID)}
            {/*Note Component form is needed here
            https://medium.com/@jonchurch/how-to-fix-react-error-rendered-fewer-hooks-than-expected-e6a378985d3c#:~:text=First%2C%20double%20check%20that%20you,fewer%20hooks%20than%20expected%20error. */}
            <SRLWrapper options={lightboxOptions}>
              <ShowTopicContent selTopicSpecs={selTopicSpecs} />
            </SRLWrapper>
          </ListGroup>
        </Card>
      );
    });
  };

  const history = useHistory();

  const handleBack2Topic = () => {
    //history.goBack();
    history.push(`/topics/${topicLinkID}`);
  };

  //moved to navBar
  const handleGo2Checkout = () => {
    //Make sure user is logged in
    setIsSigninModalShown(!isLoggedIn);

    if (isLoggedIn) {
      //Show Checkout
      history.push(`/co/${topicLinkID}`);
    }
  };

  const ShowCartContent = () => {
    if (!topic) {
      return <></>;
    }
    const selSpecs = MakeSelectCart(topic.topicID);
    if (selSpecs && Object.values(selSpecs).length > 0) {
      return <Container className="p-4">{ShowOrderSpecs(selSpecs)}</Container>;
    } else {
      return (
        <Container className="p-4">
          {/*<div
            className={`${styles.emptyCart} d-flex flex-column justify-content-center`}
          > */}
          <div
            className={`mt-4 d-flex justify-content-center ${styles.emptyCart__title}`}
          >
            {t("market.emptyCart")}
          </div>

          <div className={`mt-4 d-flex justify-content-center`}>
            <Button
              variant="primary"
              className={`${styles.emptyCart__btn}`}
              onClick={handleBack2Topic}
            >
              {t("market.continue")}
            </Button>
          </div>
          <ShowCounterAlert />
          {/*</div>*/}
        </Container>
      );
    }
  };

  return (
    <>
      {/* Navbar */}
      <ShowNavBar
        topicLinkID={topicLinkID}
        isMarket={true}
        activeKey="cart"
        isMarketOrder={true}
      />
      {/* Market Cart Contents */}
      <ShowCartContent />
      <ShowDeleteSpecModal />
      <ShowSigninModal />
    </>
  );
};
// !SECTION: MAIN FUNCTION

export default MarketCart;
