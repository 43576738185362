//!=========================================================================================================
//!MarketReceipt is MarketOrder, we changed name to "Receipt" to avoid confusion with the type "MarketOrder"
//!=========================================================================================================

import React, { useEffect, useState, useMemo } from "react";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import LoadingScreen from "src/components/loading";
import { Modal } from "react-bootstrap";

import { selectMarketOrder } from "src/selectors/marketSelector";
import { selectUser } from "src/selectors/userSelector";
import {
  useAppDispatch,
  AppDispatch,
  AppState,
  AppGetState,
} from "src/redux/store";
import { fetchOnePerson } from "src/redux/reducers/userSlice";
import ShowNavBar from "src/components/navBar";

import styles from "src/marketStyle.module.scss";
import { selectMarketOptions } from "src/selectors/marketSelector";
import { selectTopic } from "src/selectors/topicSelector";
import { fetchSingleTopicFromTopicLink } from "src/firebase/firebaseTopics";
import { fireFetchTopicMarketOptions } from "src/firebase/firebaseMarkets";
import { MarketOrder, MarketOptions, UserData } from "src/types/typings";
import {
  MakeSelectWithTopicLinkID,
  MakeSelectMarketOrders,
} from "src/components/topics/topicDetails";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  fireFetchTopicMarketOrder,
  fireFetchAllTopicMarketOrders,
} from "src/firebase/firebaseMarkets";
import SignInScreen from "src/firebase/firebaseui";
import MarketSingleOrder from "./marketSingleOrder";
import TopicMarketAllOrders from "./marketAllOrders";

// const makeSelectPayment = (orderID, paymentID) => {
//   const selPayment: MarketPaymentMethod = useSelector((state: AppState) => {
//     return selectPayment(state, orderID, paymentID);
//   });
//   return selPayment;
// };

const MakeSelectMarketOptions = ({ topicID }) => {
  const selMarketOptions: Partial<MarketOptions> | undefined = useSelector(
    (state: AppState) => {
      return selectMarketOptions(state, topicID);
    }
  );
  return selMarketOptions;
};

const MakeSelectMarketOrder = ({ orderID }) => {
  const selMarketOrder: MarketOrder | undefined = useSelector(
    (state: AppState) => {
      return selectMarketOrder(state, orderID);
    }
  );
  return selMarketOrder;
};

const makeSelectTopicCreator = (userID: string) => {
  return useSelector((state: AppState) => selectUser(state, userID));
};

const makeSelectIsLoggedIn = () => {
  return useSelector((state: AppState) => state.auth.isLoggedIn);
};

//SECTION: MAIN FUNCTION
const MarketReceipt = () => {
  const [isSigninModalShown, setIsSigninModalShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingName, setLoadingName] = useState("");
  //const [isLoggedIn, setIsLoggedIn] = useState(false);

  const isLoggedIn = useSelector((state: AppState) => {
    return state.auth.isLoggedIn;
  });
  //setIsLoggedIn(makeSelectIsLoggedIn);
  //const isLoggedIn = makeSelectIsLoggedIn();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  console.log(`location.search`, location.search);
  const queryStr = location.search;
  const values = queryString.parse(queryStr);
  const topicLinkID = values.id1 as string;
  const orderID = (values.id2 as string) ?? ("" as string);

  let isAllOrdersShown = true;
  if (orderID) {
    isAllOrdersShown = false;
  }
  console.log("<marketReceipt> isAllOrdersShown", isAllOrdersShown);
  //const { topicLinkID, orderID } = useParams();
  console.log("<marketReceipt> topicLinkID, orderID", topicLinkID, orderID);

  const appDispatch = useAppDispatch();

  const selMktOrder = MakeSelectMarketOrder({ orderID: orderID });

  const handleSigninModalClose = () => setIsSigninModalShown(false);

  const topic = MakeSelectWithTopicLinkID({ topicLinkID });
  const allMktOrders = MakeSelectMarketOrders({ topicLinkID });
  console.log("<marketReceipt> allMktOrders", allMktOrders);

  const topicID = topic?.topicID;
  const creatorID = topic?.topicCreatorID || "";
  let creator: UserData | undefined = makeSelectTopicCreator(creatorID);
  const marketOptions = MakeSelectMarketOptions({ topicID: topicID });

  const ShowSigninModal = () => {
    return (
      <Modal
        show={isSigninModalShown}
        centered={true}
        animation={true}
        onHide={handleSigninModalClose}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              ...styles,
              fontSize: 24,
              fontWeight: "bolder",
            }}
          >
            Sign-in
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SignInScreen onHide={handleSigninModalClose} />
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
    console.log("use Effect called marketReceipt");
    setIsSigninModalShown(!isLoggedIn);

    console.log("not loading");
    if (isLoggedIn) {
      console.log("user logged in");
      // if (topicID && !selMktOrder && topicLinkID && !isAllOrdersShown) {
      //   console.log("fetch market order", topicID, topicLinkID);
      //   //Fetch the market order if not in redux store already
      //   setIsLoading(true);
      //   setLoadingName("Market Order");
      //   console.log(
      //     `cannot find market order, begin fetching from firebase`,
      //     loadingName
      //   );
      //   appDispatch(fireFetchTopicMarketOrder(topicLinkID, topicID, orderID));
      // } else

      if (!creator && creatorID) {
        setIsLoading(true);
        setLoadingName("creator");
        appDispatch(fetchCreator(creatorID));
        console.log("fetch user", creatorID, loadingName);
      } else if (!topicID) {
        //No topicID, have to fetch topic
        setIsLoading(true);
        setLoadingName("Topic");
        console.log("No topicID, fetch topic fresh", loadingName, topicLinkID);
        appDispatch(fetchSingleTopicFromTopicLink(topicLinkID));
      } else if (!marketOptions) {
        console.log("<topicMarket useEffect> marketOptions");
        setIsLoading(true);
        appDispatch(fireFetchTopicMarketOptions(topicID));
      }
      // else if (isAllOrdersShown && topicID && !allMktOrders) {
      //   console.log("falling into ALlMarketOrders");
      //   setIsLoading(true);
      //   setLoadingName("All Orders");
      //   console.log("fetching all topic market orders", loadingName);
      //   appDispatch(fireFetchAllTopicMarketOrders(topicID, topicLinkID));
      // }
      else {
        setLoadingName("Finished");
        console.log(
          "<marketReceipt>finished loading all",
          loadingName,
          topicID,
          allMktOrders
        );
        setIsLoading(false);
      }
    } else {
      console.log("user not logged in!");
    }
  }, [isLoggedIn, topicID, topic, setIsLoading, creator]);

  if (!isLoggedIn) {
    console.log("err! not logged in!");
    return (
      <>
        <ShowSigninModal />
      </>
    );
  }

  if (!topic || !topicID || !creator || isLoading) {
    console.log("err! missing var 2!", topic, creator, topicID);
    return <LoadingScreen />;
  }

  if (topicLinkID && orderID && marketOptions) {
    console.log(
      "<marketReceipt> going to MarketSingleOrder",
      orderID,
      topic,
      creator,
      marketOptions
    );
    return (
      <MarketSingleOrder
        orderID={orderID}
        topic={topic}
        creator={creator}
        marketOptions={marketOptions}
      />
    );
  } else {
    return <TopicMarketAllOrders />;
  }
};
//!SECTION

const fetchCreator =
  (creatorID: string) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const creator = await dispatch(fetchOnePerson(creatorID));
    return creator;
  };

export default MarketReceipt;
