import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Navbar,
  Nav,
  Image,
  Jumbotron,
} from "react-bootstrap";
import * as yup from "yup";
import { AppState } from "src/redux/store";
import { useSelector } from "react-redux";
import _ from "lodash";
import SimlyLinkLogo from "src/simplyLinkLogo.svg";
import SignInScreen from "src/firebase/firebaseui";
import { useTranslation } from "react-i18next";
import { langs, MarketSpec } from "src/types/typings";
import { FiShoppingCart } from "react-icons/fi";
import styles from "src/marketStyle.module.scss";
import { fireFetchAllTopicMarketOrders } from "src/firebase/firebaseMarkets";
import {
  IoBagCheckOutline,
  IoCart,
  IoPerson,
  IoPersonCircleOutline,
} from "react-icons/io5";
import {
  MakeSelectMarkets,
  MakeSelectMarketOrders,
} from "src/components/topics/topicDetails";
import { MakeSelectCart } from "src/components/topics/marketCart";
import { MakeSelectWithTopicLinkID } from "src/components/topics/topicDetails";

//activeKey: cart, topic, home
const ShowNavBar = (props) => {
  const { t, i18n } = useTranslation();
  const curLang = i18n.language;
  const isLoggedIn = useSelector((state: AppState) => {
    console.log("<ShowNavBar> log in status is", state.auth.isLoggedIn);
    return state.auth.isLoggedIn;
  });

  let initLang = langs.TC;
  if (curLang == "en") {
    initLang = langs.EN;
  } else if (curLang == "tc") {
    initLang = langs.TC;
  }

  const [langVal, setLangVal] = useState<langs>(initLang);
  const [isSigninModalShown, setIsSigninModalShown] = useState(false);
  const [isCheckoutPress, setIsCheckoutPressed] = useState(false);
  const handleSigninModalClose = () => setIsSigninModalShown(false);
  const [isMobile, setIsMobile] = useState(false);

  const topicLinkID = props.topicLinkID;
  //const isMarket = props.isMarket;
  const activeKey = props.activeKey;
  const mktOrderID = props.mktOrderID;
  //const isMarketOrder = props.isMarketOrder;
  const selMarket = MakeSelectMarkets({
    topicLinkID: topicLinkID,
  });
  const topic = MakeSelectWithTopicLinkID({ topicLinkID });
  let isMarket = false;
  if (selMarket && selMarket.length >= 1) {
    console.log("<navBar> selMarket is", selMarket);
    isMarket = true;
  }
  let isMarketOrder = false;
  const allMktOrders = MakeSelectMarketOrders({ topicLinkID });
  if (allMktOrders && allMktOrders.length >= 1) {
    console.log("<navBar> allMktOrders is", allMktOrders);
    isMarketOrder = true;
  } else {
    console.log("<navBar> allMktOrders is none", allMktOrders);
  }

  let isCheckout = false;
  let isCart = false;
  let topicSpecs = [] as MarketSpec[];

  if (topic) {
    //Note selSpecs return a record of <topicID, MarketSpec[]>
    const selSpecs = MakeSelectCart(topic.topicID);
    const selTopicIDs = Object.keys(selSpecs);

    if (selTopicIDs.length > 0) {
      const topicID = selTopicIDs[0];
      topicSpecs = selSpecs[topicID];
    }
    console.log("selSpecs are", selSpecs);

    if (activeKey === "cart" && selSpecs && topicSpecs.length > 0) {
      isCheckout = true;
    }

    if (activeKey === "home" && selSpecs && topicSpecs.length > 0) {
      isCart = true;
    }
  }

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 576) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    console.log("handleResize called");
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const history = useHistory();
  const handleGo2Cart = () => {
    history.push(`/c/${topicLinkID}`);
  };

  const ShowSigninModal = () => {
    return (
      <Modal
        show={isSigninModalShown}
        centered={true}
        animation={true}
        onHide={handleSigninModalClose}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              ...styles,
              fontSize: 24,
              fontWeight: "bolder",
            }}
          >
            {t("nav.signin")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SignInScreen onHide={handleSigninModalClose} />
        </Modal.Body>
      </Modal>
    );
  };

  const GetTopicNavItem = () => {
    if (topicLinkID && topicLinkID !== "" && topicLinkID != "undefined") {
      return (
        <Nav.Item>
          <Nav.Link eventKey="home">{t("nav.home")}</Nav.Link>
        </Nav.Item>
      );
    } else {
      return <></>;
    }
  };

  const handleGo2Checkout = () => {
    //Make sure user is logged in
    setIsSigninModalShown(!isLoggedIn);

    if (isLoggedIn) {
      //Show Checkout
      history.push(`/co/${topicLinkID}`);
    }
  };

  const handleGo2Signin = () => {
    setIsSigninModalShown(true);
  };

  const GetMarketNavItem = () => {
    if (isMarket && topicSpecs) {
      return (
        <Nav.Item>
          <Nav.Link eventKey="cart">
            {t("nav.cart")}
            {`[${topicSpecs.length}]`}
          </Nav.Link>
        </Nav.Item>
      );
    } else {
      return <></>;
    }
  };

  const GetMarketCheckoutItem = () => {
    if (
      (activeKey == "checkout" || activeKey == "cart") &&
      topicSpecs.length > 0
    ) {
      return (
        <Nav.Item>
          <Nav.Link eventKey="checkout">{t("market.checkout")}</Nav.Link>
        </Nav.Item>
      );
    } else {
      return <></>;
    }
  };

  const GetMarketOrderItem = () => {
    if (activeKey == "order" || isMarketOrder) {
      return (
        <Nav.Item>
          <Nav.Link eventKey="order">{t("market.order")}</Nav.Link>
        </Nav.Item>
      );
    } else {
      return <></>;
    }
  };

  const LayoutCheckoutBtn = () => {
    if (isCheckout && isMobile) {
      return (
        <span className="mr-sm-2">
          <Button variant="success" onClick={handleGo2Checkout}>
            <IoBagCheckOutline
              size={24}
              className={`${styles.marketCartBtn} mb-1`}
            />
            <span className={`.success ml-2 ${styles.checkoutBtn}`}>
              {t("market.checkout")}
            </span>
          </Button>
        </span>
      );
    }
  };

  const LayoutCartBtn = () => {
    if (isCart && isMobile) {
      return (
        <span className="mr-sm-2">
          <Button variant="success" onClick={handleGo2Cart}>
            <IoCart size={24} className={`${styles.marketCartBtn} mb-1`} />
            <span className={`.success ml-2 ${styles.checkoutBtn}`}>
              {t("nav.cart")}
              {`[${topicSpecs.length}]`}
            </span>
          </Button>
        </span>
      );
    }
  };

  const handleGo2Hub = (selectedKey: string | null) => {
    if (selectedKey) {
      if (selectedKey === "home") {
        // if (topicLinkID) {
        //   history.push({
        //     pathname: "/home",
        //     search: `?id1=${topicLinkID}`,
        //   });
        // } else {
        //   history.push({
        //     pathname: "/home",
        //   });
        // }
        handleGo2Topic();
      }
      // else if (selectedKey === "topic") {
      //   handleGo2Topic();
      // }
      else if (selectedKey === "cart") {
        handleGo2Cart();
      } else if (selectedKey === "checkout") {
        handleGo2Checkout();
      } else if (selectedKey === "order") {
        handleGo2Order();
      }
    }
  };

  const handleGo2Order = () => {
    // if (mktOrderID && topicLinkID) {
    //   history.push({
    //     pathname: "/mr",
    //     search: `?id1=${topicLinkID}&id2=${mktOrderID}`,
    //   });
    // } else if (!mktOrderID && topicLinkID) {
    history.push({
      pathname: "/mr",
      search: `?id1=${topicLinkID}`,
    });
    //}
  };

  const handleGo2Topic = () => {
    history.push(`/topics/${topicLinkID}`);
  };

  const changeLanguage = (val: langs) => {
    let lng = "en";
    switch (val) {
      case langs.EN:
        lng = "en";
        break;
      case langs.TC:
        lng = "tc";
        break;
    }
    i18n.changeLanguage(lng);
  };

  return (
    <Navbar
      variant="light"
      bg="whiteShadow"
      sticky="top"
      collapseOnSelect={true}
      expand="sm"
    >
      <Navbar.Brand href={`/home/?id1=${topicLinkID}`}>
        <Image
          src={SimlyLinkLogo}
          width="2rem"
          height="2rem"
          className="d-inline-block align-center bg-white"
          alt="SimplyLink"
        />
      </Navbar.Brand>
      {LayoutCheckoutBtn()}
      {LayoutCartBtn()}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav
          activeKey={activeKey}
          className="mr-auto"
          onSelect={(selectedKey) => handleGo2Hub(selectedKey)}
        >
          {/* <Nav.Item>
            <Nav.Link eventKey="kalink">{t("nav.kalink")}</Nav.Link>
          </Nav.Item> */}
          <GetTopicNavItem />
          <GetMarketNavItem />
          <GetMarketCheckoutItem />
          <GetMarketOrderItem />
        </Nav>

        <span className="d-flex">
          <Button
            variant={langVal == langs.TC ? "secondary" : "light"}
            onClick={() => {
              setLangVal(langs.TC);
              changeLanguage(langs.TC);
            }}
            className="mt-2 mb-2"
          >
            中
          </Button>
          <Button
            variant={langVal == langs.EN ? "secondary" : "light"}
            onClick={() => {
              setLangVal(langs.EN);
              changeLanguage(langs.EN);
            }}
            className="mt-2 mb-2"
          >
            EN
          </Button>

          <ShowSigninModal />
        </span>
        <span className="mr-sm-2">
          <Button variant="light" onClick={handleGo2Signin} className="p-0">
            <IoPersonCircleOutline size={36} />
          </Button>
        </span>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default ShowNavBar;

{
  /* <Button
          variant="outline-dark"
          onClick={handleGo2Cart}
          className={`border-1 mr-2`}
        >
          <span>{t("mktNav.cart")}</span>
          <FiShoppingCart className={`${styles.marketCartBtn}`} />
        </Button> */
}
