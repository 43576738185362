/**
|--------------------------------------------------
| src/firebase/firebaseTopics
|--------------------------------------------------
*/
import { database } from "src/config/fbConfig";
import { AppDispatch, AppState, AppGetState } from "src/redux/store";
import {
  storeTopicDetailsToRedux,
  fetchTopicGroups,
  prepareTopicData,
} from "src/redux/utils/topicUtils";
import { fetchOnePerson } from "src/redux/reducers/userSlice";

import { fetchTopicSuccess } from "src/redux/reducers/topicSlice";
import { UserData, TopicData, GroupData } from "src/types/typings";

//Get the topic details, except group names, avatars or user names for DM
export const fetchSingleTopicDetails = async (topicID: string) => {
  const ref = database.ref(`/topics/${topicID}`);
  const snap = (await ref.once("value")) as Record<string, any>;
  console.log("topicSnapshot for topic", topicID, snap);
  if (snap.exists()) {
    console.log("fetchSingleTopicDetails snap val", snap.val());
    return snap;
  } else {
    //No snap
    console.log(`fetchSingleTopicDetails no snapshot ${topicID}`);
    return null;
  }
};

export const fetchSingleTopicFromTopicLink = (topicLinkID: string) => async (
  dispatch: AppDispatch,
  getState: AppGetState
) => {
  const topicID = await fetchTopicIDFromTopicLinkID(topicLinkID);
  if (topicID !== null && topicID !== "") {
    let topic: Partial<TopicData> = {
      topicID: topicID,
      topicLinkID: topicLinkID,
    };
    dispatch(fetchSingleTopic(topic));
  }
};

const fetchTopicIDFromTopicLinkID = async (topicLinkID: string) => {
  const ref = database.ref(`/topicLink/${topicLinkID}`);
  const snap = (await ref.once("value")) as Record<string, any>;
  if (snap.exists()) {
    console.log("fetched topicID from link is", snap.val());
    return snap.val() as string;
  } else {
    console.log("failed to get the topic from linkID", topicLinkID);
    return null;
  }
};

export const fetchSingleTopic = (topic: Partial<TopicData>) => async (
  dispatch: AppDispatch,
  getState: AppGetState
) => {
  // let topic: Partial<TopicData> = {
  //   topicID: topicID,
  // };
  console.log("<fetchSingleTopic>", topic.topicID)
  const topicID = topic.topicID as string;
  if (topicID === "") {
    return;
  }
  const topicSnapshot = await fetchSingleTopicDetails(topicID);

  if (topicSnapshot) {
    dispatch(storeTopicDetailsToRedux(topicSnapshot));

    const topicDM = topicSnapshot.val().topicDM;
    let groups: Partial<GroupData>[] = [];
    let user: Partial<UserData> = {};
    if (!topicDM) {
      //Fetch Group Data
      const topicGroupsData = topicSnapshot.val().topicGroups;
      //Fetch topic groups
      const groupIDs = Object.keys(topicGroupsData).filter(
        (k) => topicGroupsData[k]
      );
      groups = await dispatch(fetchTopicGroups(groupIDs));
      let creatorID = topicSnapshot.val().topicCreatorID;
      if (creatorID === null || creatorID === "") {
        return;
      }
      user = await dispatch(fetchOnePerson(creatorID));

      const finalTopic = await prepareTopicData(
        groups,
        user,
        topicSnapshot,
        topic
      );
      dispatch(fetchTopicSuccess(finalTopic));
      return finalTopic;
    } else {
      //no topic snapshot
      return {};
    }
  }
};
