import React, { useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import _ from "lodash";
import { useFormikContext } from "formik";

import {
  marketDeliveryOption,
  lockerArea,
  LockerDistData,
  LockerLocData,
  FormFields,
} from "src/types/typings";

import {
  fireFetchLockerDist,
  fireFetchLockerLoc,
} from "src/firebase/firebaseMarkets";

import { useTranslation } from "react-i18next";

//District is the level below hk/kln/nt (e.g. Sha Tin, Tin Hau)
const getLockerDist = async (selLockerArea: lockerArea) => {
  let lockerAreaStr = "";
  console.log(`selLockerArea`, selLockerArea);

  //https://stackoverflow.com/questions/27747437/typescript-enum-switch-not-working/43178834
  //NOTE: the "+" plus sign is needed, otherwise comparison fails!
  switch (+selLockerArea) {
    case lockerArea.HK:
      lockerAreaStr = "hk";
      console.log(`hk`, lockerAreaStr);
      break;
    case lockerArea.KLN:
      lockerAreaStr = "kl";
      console.log(`kl`, lockerAreaStr);
      break;
    case lockerArea.NT:
      lockerAreaStr = "nt";
      console.log(`nt`, lockerAreaStr);
      break;
    default:
      console.log(`none of the above`, lockerAreaStr);
      lockerAreaStr = "";
      break;
  }
  console.log("calling getLockerDist", selLockerArea, lockerAreaStr);
  const distData = await fireFetchLockerDist(lockerAreaStr);
  console.log("dist list", distData);
  if (distData) {
    // const locDesc = distData.map((e) => e.distName) as string[];
    // console.log(`locDesc`, locDesc);
    //setLockerDist(distData);
    return distData;
  }
};

const fetchLockerDistData = async (orderLockerArea: lockerArea) => {
  if (orderLockerArea != lockerArea.NOTSET) {
    const res = await getLockerDist(orderLockerArea);
    console.log("lockerDist is", res ?? "none");
    return res;
  }
};

export const LayoutLockerDist = (props) => {
  const { values, setFieldValue, handleChange } =
    useFormikContext<FormFields>();
  const { t, i18n } = useTranslation();
  //const [lockerDist, setLockerDist] = useState<Partial<LockerDistData>[]>([]);
  //const [field, meta] = useField(props);

  useEffect(() => {
    console.log("LayoutLockerDist useEffect called");

    let isCurrent = true;

    fetchLockerDistData(values.orderLockerArea).then((res) => {
      if (!!isCurrent && res) {
        setFieldValue("orderLockerDists", res);
      }
    });

    return () => {
      isCurrent = false;
    };
  }, [values.orderLockerArea, setFieldValue]);

  //Guard statements
  if (
    values.orderDeliveryMethod != marketDeliveryOption.COURIER ||
    values.orderLockerArea == 0 ||
    values.orderLockerType == 0
  ) {
    console.log("NOT calling setSelLockerArea", values.orderDeliveryMethod);
    return <></>;
  }

  console.log("lockerArea is", values.orderLockerArea);

  return (
    <Form.Group>
      <Form.Row>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <Form.Label>{t("market.chkoutLockerDistrict")}</Form.Label>
          <Form.Control
            name="orderDistID"
            as="select"
            value={values.orderDistID}
            onChange={handleChange}
          >
            <option value={"notset"}>{t("market.chkoutOptions")}</option>
            {values.orderLockerDists &&
              values.orderLockerDists.length > 0 &&
              values.orderLockerDists.map((e) => {
                if (i18n.language == "tc") {
                  return (
                    <option key={e.distID} value={e.distID}>
                      {e.distNameCT}
                    </option>
                  );
                } else {
                  return (
                    <option key={e.distID} value={e.distID}>
                      {e.distName}
                    </option>
                  );
                }
              })}
          </Form.Control>
        </Col>
      </Form.Row>
    </Form.Group>
  );
};

export const LayoutShippingAddress = () => {
  const { values, handleChange } = useFormikContext<FormFields>();
  const { t, i18n } = useTranslation();

  //Guard statements
  if (
    !values.orderDeliveryMethod ||
    values.orderDeliveryMethod != marketDeliveryOption.COURIER
  ) {
    return <></>;
  }

  console.log("ZZZ orderDeliveryMethod is:", values.orderDeliveryMethod);

  return (
    <Form.Group>
      <Form.Row>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <Form.Label>{t("market.chkoutShipAddress")}</Form.Label>
          <Form.Control
            as="select"
            name="orderShippingAddress"
            value={values.orderShippingAddress}
            onChange={handleChange}
          >
            <option value={0}>{t("market.chkoutOptions")}</option>
            <option value={1}>{t("market.chkoutShipLocker")}</option>
            <option value={2}>{t("market.chkoutShipAddressInput")}</option>
          </Form.Control>
        </Col>
      </Form.Row>
    </Form.Group>
  );
};

export const LayoutLockerArea = () => {
  const { values, handleChange } = useFormikContext<FormFields>();
  const { t, i18n } = useTranslation();

  //Guard statements
  if (
    values.orderDeliveryMethod != marketDeliveryOption.COURIER ||
    values.orderLockerType == 0 ||
    values.orderShippingAddress != 1
  ) {
    return <></>;
  }

  return (
    <Form.Group>
      <Form.Row>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <Form.Label>{t("market.chkoutLockerArea")}</Form.Label>
          <Form.Control
            name="orderLockerArea"
            as="select"
            value={values.orderLockerArea}
            onChange={handleChange}
          >
            <option value={lockerArea.NOTSET}>
              {t("market.chkoutOptions")}
            </option>
            <option value={lockerArea.HK}>{t("market.chkoutLockerHK")}</option>
            <option value={lockerArea.KLN}>{t("market.chkoutLockerKL")}</option>
            <option value={lockerArea.NT}>{t("market.chkoutLockerNT")}</option>
          </Form.Control>
        </Col>
      </Form.Row>
    </Form.Group>
  );
};

export const LayoutLockerType = () => {
  const { values, handleChange } = useFormikContext<FormFields>();
  const { t, i18n } = useTranslation();

  console.log("YYY orderShippingAddress is", values.orderShippingAddress);

  //Guard statements
  if (
    values.orderDeliveryMethod != marketDeliveryOption.COURIER ||
    values.orderShippingAddress != 1
  ) {
    return <></>;
  }

  return (
    <Form.Group>
      <Form.Row>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <Form.Label>{t("market.chkoutLockerType")}</Form.Label>
          <Form.Control
            name="orderLockerType"
            as="select"
            value={values.orderLockerType}
            onChange={handleChange}
          >
            <option value={0}>{t("market.chkoutOptions")}</option>
            <option value={1}>{t("market.chkoutShipTypeLocker")}</option>
            <option value={2}>{t("market.chkoutShipTypeStore")}</option>
          </Form.Control>
        </Col>
      </Form.Row>
    </Form.Group>
  );
};

export const LayoutPickupDetails = (props: {
  mktPickupLocs: Record<string, string> | undefined;
}) => {
  const { values, handleChange } = useFormikContext<FormFields>();
  const { t, i18n } = useTranslation();

  const pickupLocs = props.mktPickupLocs;

  //Guard statements
  if (
    !values.orderDeliveryMethod ||
    values.orderDeliveryMethod != marketDeliveryOption.PICKUP
  ) {
    return <></>;
  }
  if (!(pickupLocs && Object.keys(pickupLocs).length > 0)) {
    return <></>;
  }

  const getPickupLocs = () => {
    if (pickupLocs) {
      const pickupLocIDs = Object.keys(pickupLocs);
      return pickupLocIDs.map((pickupLocID) => {
        const pickupLocName = pickupLocs[pickupLocID];
        return <option value={pickupLocName}>{pickupLocName}</option>;
      });
    }
  };

  return (
    <Form.Group>
      <Form.Row>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <Form.Label>{t("market.chkoutPickupDetails")}</Form.Label>
          <Form.Control
            name="orderPickupDetails"
            as="select"
            value={values.orderPickupDetails}
            onChange={handleChange}
          >
            <option value={"notset"}>{t("market.chkoutOptions")}</option>
            {getPickupLocs()}
          </Form.Control>
        </Col>
      </Form.Row>
    </Form.Group>
  );
};

export const LayoutShippingMethod = (props: {
  mktDeliveryOpt: marketDeliveryOption | undefined;
}) => {
  const { values, handleChange } = useFormikContext<FormFields>();
  const { t, i18n } = useTranslation();

  const mktDeliveryOpt = props.mktDeliveryOpt;

  if (!mktDeliveryOpt) {
    return <></>;
  }

  const getShippingMethod = () => {
    switch (mktDeliveryOpt) {
      case marketDeliveryOption.COURIER:
        return (
          <Form.Control name="orderDeliveryMethod" as="select" disabled>
            <option value={marketDeliveryOption.COURIER}>
              {t("market.chkoutCourier")}
            </option>
          </Form.Control>
        );
        break;
      case marketDeliveryOption.PICKUP:
        return (
          <Form.Control name="orderDeliveryMethod" as="select" disabled>
            <option value={marketDeliveryOption.PICKUP}>
              {t("market.chkoutPickup")}
            </option>
          </Form.Control>
        );
        break;
      case marketDeliveryOption.BOTH:
        return (
          <Form.Control
            name="orderDeliveryMethod"
            as="select"
            onChange={handleChange}
          >
            <option value={marketDeliveryOption.NOTSET}>
              {t("market.chkoutOptions")}
            </option>
            <option value={marketDeliveryOption.COURIER}>
              {t("market.chkoutCourier")}
            </option>
            <option value={marketDeliveryOption.PICKUP}>
              {t("market.chkoutPickup")}
            </option>
          </Form.Control>
        );
        break;
      default:
        return <Form.Control placeholder="Please Contact Seller" disabled />;
        break;
    }
  };
  return (
    <Form.Group>
      <Form.Row>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <Form.Label>{t("market.chkoutShipMethod")}</Form.Label>
          {getShippingMethod()}
        </Col>
      </Form.Row>
    </Form.Group>
  );
};

//Location is the level below district (i.e. the exact locker location)
const fetchLockerLocData = async (orderLockerDist: Partial<LockerDistData>) => {
  const locs = orderLockerDist.distLocs;
  if (locs) {
    const locCodes = Object.keys(locs);
    const final: Partial<LockerLocData>[] = [];
    await Promise.all(
      locCodes.map(async (locCode) => {
        const locDetails = await fireFetchLockerLoc(locCode);
        if (locDetails) {
          console.log("pushing loc details");
          final.push(locDetails);
        }
      })
    );
    return final;
  }
};

export const LayoutLockerLocation = (props) => {
  const { values, setFieldValue, handleChange } =
    useFormikContext<FormFields>();
  const { t, i18n } = useTranslation();
  //const [lockerDist, setLockerDist] = useState<Partial<LockerDistData>[]>([]);

  useEffect(() => {
    console.log("LayoutLockerLocation useEffect called");

    let isCurrent = true;

    const distID = values.orderDistID;
    console.log("***distID", distID);
    const lockerDists = values.orderLockerDists;
    console.log("***lockerDists", lockerDists);
    const distData = lockerDists.filter((e) => e.distID == distID);
    console.log("***distData", distData);
    let selOrderDist = {} as Partial<LockerDistData>;
    if (distData.length == 1) {
      selOrderDist = distData[0];
    }

    if (selOrderDist) {
      fetchLockerLocData(selOrderDist).then((res) => {
        if (!!isCurrent && res) {
          setFieldValue("orderLockerLocs", res);
        }
      });
    }

    return () => {
      isCurrent = false;
    };
  }, [values.orderDistID, setFieldValue]);

  //Guard statements
  if (
    values.orderDeliveryMethod != marketDeliveryOption.COURIER ||
    values.orderLockerArea == 0 ||
    !values.orderDistID ||
    _.isEmpty(values.orderDistID) ||
    values.orderLockerType == 0
  ) {
    return <></>;
  }

  // const LayoutLockerLocation2 = () => {
  //   if (lockerLocs) {
  //     return lockerLocs.map((e) => {
  //       if (e.locID && e.address) {
  //         return <option value={e.locID}>{e.address}</option>;
  //       }
  //     });
  //   }
  // };

  return (
    <Form.Group>
      <Form.Label>{t("market.chkoutLockerLocation")}</Form.Label>
      <Form.Control
        as="select"
        name="orderEFLockerCode"
        value={values.orderEFLockerCode}
        onChange={handleChange}
      >
        <option value={""}>{t("market.chkoutOptions")}</option>
        {values.orderLockerLocs &&
          values.orderLockerLocs.length > 0 &&
          values.orderLockerLocs.map((e) => {
            if (i18n.language == "tc") {
              return (
                <option key={e.locID} value={e.locID}>
                  {e.addressCT}
                </option>
              );
            } else {
              return (
                <option key={e.locID} value={e.locID}>
                  {e.address}
                </option>
              );
            }
          })}
      </Form.Control>
    </Form.Group>
  );
};

export const LayoutInputAddress = () => {
  const { values, setFieldValue, handleChange } =
    useFormikContext<FormFields>();
  const { t, i18n } = useTranslation();
  //Guard statements
  if (
    values.orderDeliveryMethod != marketDeliveryOption.COURIER ||
    values.orderShippingAddress != 2
  ) {
    return <></>;
  }
  return (
    <Form.Group>
      <Form.Group>
        <Form.Label>{t("market.chkoutShipAddress")}</Form.Label>
        <Form.Control
          placeholder={t("market.chkoutShipAddressL1")}
          value={values.orderAddress1}
          name="orderAddress1"
          onChange={handleChange}
        />
        <Form.Control
          placeholder={t("market.chkoutShipAddressL2")}
          value={values.orderAddress2}
          name="orderAddress2"
          className="mt-2"
        />
      </Form.Group>
      <Form.Group>
        <Form.Row>
          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
            <Form.Group controlId="city">
              <Form.Label>{t("market.chkoutShipAddressCity")}</Form.Label>
              <Form.Control />
            </Form.Group>
          </Col>

          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
            <Form.Group controlId="country">
              <Form.Label>{t("market.chkoutShipAddressCountry")}</Form.Label>
              <Form.Control />
            </Form.Group>
          </Col>
        </Form.Row>
      </Form.Group>
    </Form.Group>
  );
};
