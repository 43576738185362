/**
|--------------------------------------------------
| src/redux/reducers/groupSlice
|--------------------------------------------------
*/

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GroupData, GroupState } from "src/types/typings";
import { fetchGroupCredentials } from "src/firebase/firebaseGroups";
import { AppDispatch, AppState } from "src/redux/store";
import { cleanPhotoLink } from "src/firebase/firebaseGlobal";
import _ from "lodash";

const groupsInitialState: GroupState = {
  data: {},
  isLoading: false,
  currentRequestId: undefined,
  error: null,
};

interface ValidationErrors {
  errorMessage: string;
  field_errors: Record<string, string>;
}

export const fetchGroupCredentialsThunk = createAsyncThunk<
  Partial<GroupData>,
  string,
  {
    state: AppState;
    requestId: string;
  }
>(
  "groups/fetchGroupCredentials",
  async (groupID: string, { getState, requestId }) => {
    const curState = getState();
    const { currentRequestId, isLoading } = curState.groups;
    // if (!isLoading || requestId !== currentRequestId) {
    //   console.log(
    //     "currently loading group ID",
    //     groupID,
    //     "request ID is",
    //     currentRequestId,
    //     "not loading dupes"
    //   );
    //   return {} as Partial<GroupData>;
    // }
    const groupData = await fetchGroupCredentials(groupID);
    const val = groupData;

    //Special care for gs://
    let strURL = val.groupAvatar ?? "";
    const cleanStrURL = await cleanPhotoLink(strURL);
    console.log("group cleanStrURL is", cleanStrURL);

    const group: Partial<GroupData> = {
      groupID: groupID,
      groupName: val.groupName,
      groupAvatar: cleanStrURL,
      groupBackground: val.groupBackground,
      groupCreationTimestamp: val.groupCreationTimestamp,
      groupDetails: val.groupDetails,
      groupMembersCount: val.groupMembersCount,
      groupLinksCount: val.groupLinksCount,
      groupPrivacy: val.groupPrivacy,
      groupUserDefinedUID: val.groupUserDefinedUID,
    };
    console.log("group data is ", groupID, group);
    return group as Partial<GroupData>;
  }
  //Condition is not set for group because in some cases (groupVC for example) we just want to force update all credentials
  // {
  //   condition: (groupID, { getState, extra }) => {
  //     const rootState = getState() as AppState;
  //     const targetGroup = rootState.groups.data.groupID;
  //     console.log(
  //       "condition for target group",
  //       targetGroup,
  //       "groupID",
  //       groupID
  //     );
  //     if (targetGroup) {
  //       console.log("group credentials already loaded", groupID);
  //       return false;
  //     } else {
  //       console.log("continue loading group creditial", groupID);
  //     }
  //   },
  // }
);

export const groupSlice = createSlice({
  name: "groups",
  initialState: groupsInitialState,
  reducers: {
    fetchGroupSuccess(state, action: PayloadAction<Partial<GroupData>>) {
      const payload = action.payload;
      const groupID = payload.groupID as string;
      state.data[groupID] = Object.assign({}, state.data[groupID], payload);
    },
  },
  extraReducers: (builder) => {
    // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
    builder.addCase(fetchGroupCredentialsThunk.pending, (state, action) => {
      //console.log("fetchGroupCredentialsThunk pending");
      state.isLoading = true;
      state.currentRequestId = action.meta.requestId;
      const groupID = action.meta.arg as string;
    });
    builder.addCase(fetchGroupCredentialsThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      const groupID = payload.groupID as string;

      if (payload && !_.isEmpty(payload)) {
        state.data[groupID] = Object.assign({}, state.data[groupID], payload);
        state.isLoading = false;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchGroupCredentialsThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.currentRequestId = undefined;
      console.log("fetchGroupCredentialsThunk rejected");
    });
  },
});

export const { fetchGroupSuccess } = groupSlice.actions;

export default groupSlice.reducer;
