/**
|--------------------------------------------------
| src/redux/selectors/userSelector
|--------------------------------------------------
*/

import { createSelector } from "reselect";
import { createCachedSelector } from "re-reselect";
import { AppState } from "src/redux/store";
import { selectTopic } from "./topicSelector";
import { MarketData, MarketSpec } from "src/types/typings";
import _ from "lodash";
import { pseudoRandomBytes } from "crypto";
import { string } from "yup/lib/locale";

export const selectUser = createSelector(
  (state: AppState) => state.users.data,
  (_, userID: string) => userID,
  (users, userID) => {
    const user = users[userID];
    return user;
  }
);
