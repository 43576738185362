import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { StyledDropzone } from "./fileDropZone.js";
import moment from "moment";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/loading";
import { run as runHolder } from "holderjs/holder";

import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  Card,
  Image,
  Accordion,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import styles from "src/marketStyle.module.scss";

import { useAppDispatch, AppState } from "src/redux/store";

import {
  TopicData,
  MarketOrder,
  MarketOptions,
  marketDeliveryOption,
  lockerArea,
  LockerDistData,
  LockerLocData,
  MarketSpec,
  marketShippingFeeOption,
  MarketOrderSpec,
  marketOrderStatus,
  marketPaymentStatus,
  UserData,
  MarketContactMethod,
  MarketPaymentMethod,
} from "src/types/typings";

import { IoCopyOutline, IoQrCodeSharp } from "react-icons/io5";

import { selectMarketOrder } from "src/selectors/marketSelector";
import { getDynamicLink } from "src/components/topics/dynamicLink";
import {
  uploadMarketDLink,
  fetchSellerDLink,
} from "src/firebase/firebaseMarkets";
import { fetchMarketOrderSuccess } from "src/redux/reducers/marketOrderSlice";
import { fireFetchLockerLoc } from "src/firebase/firebaseMarkets";
import ShowNavBar from "src/components/navBar";

import { fireFetchTopicMarketOrder } from "src/firebase/firebaseMarkets";

import _ from "lodash";
import { SRLWrapper } from "simple-react-lightbox";

const MakeSelectMarketOrder = ({ orderID }) => {
  const selMarketOrder: MarketOrder | undefined = useSelector(
    (state: AppState) => {
      return selectMarketOrder(state, orderID);
    }
  );
  return selMarketOrder;
};

//SECTION: MAIN FUNCTION
const MarketSingleOrder = (props: {
  orderID: string;
  topic: TopicData;
  creator: UserData;
  marketOptions: Partial<MarketOptions>;
}) => {
  const { t, i18n } = useTranslation();
  const orderID = props.orderID;
  const topic = props.topic;
  const creator = props.creator;
  const marketOptions = props.marketOptions;
  const appDispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [locDetails, setLocDetails] = useState<Partial<LockerLocData>>({});
  const [isDLinkModalShown, setIsDLinkModalShown] = useState(false);
  const selMktOrder = MakeSelectMarketOrder({ orderID: orderID });

  const getLockerDetails = async () => {
    if (selMktOrder) {
      const locDetailsTmp = await fireFetchLockerLoc(
        selMktOrder.orderEFLockerCode
      );
      if (locDetailsTmp) {
        console.log("<marketSingleOrder> locDetailsTmp", locDetailsTmp);
        setLocDetails(locDetailsTmp);
      }
    }
  };

  const handleDLinkModalClose = () => setIsDLinkModalShown(false);

  const ShowDLinkModal = () => {
    const [copied, setCopied] = useState(false);
    if (selMktOrder) {
      return (
        <Modal
          show={isDLinkModalShown}
          centered={true}
          animation={true}
          onHide={handleDLinkModalClose}
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title
              style={{
                fontSize: 24,
                fontWeight: "bolder",
              }}
            >
              {t("market.receiptLink")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Link className={`${styles.receiptLink}`}>
              {selMktOrder.orderDLink}
            </Link>

            <Button
              variant={copied ? "success" : "primary"}
              className={`${styles.statusBtn} w-100 mt-2`}
              onClick={() => {
                navigator.clipboard.writeText(`${selMktOrder.orderDLink}`);
                setCopied(true);
              }}
            >
              {copied
                ? t("market.receiptLinkCopied")
                : t("market.receiptLinkCopy")}
            </Button>
          </Modal.Body>
        </Modal>
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    console.log("<marketSingleOrder> 0");
    if (!isLoading) {
      if (orderID && topic && !selMktOrder) {
        console.log("<marketSingleOrder> begin loading order");
        //Fetch the market order if not in redux store already
        setIsLoading(true);
        appDispatch(
          fireFetchTopicMarketOrder(topic.topicLinkID, topic.topicID, orderID)
        );
      } else {
        console.log("<marketSingleOrder> finished loading order");
        setIsLoading(false);
      }
    } else {
      console.log("<marketSingleOrder> still loading");
    }
  }, [selMktOrder, setIsLoading]);

  useEffect(() => {
    console.log("<marketSingleOrder> useEffect called");
    if (
      selMktOrder &&
      selMktOrder.orderEFLockerCode !== "" &&
      _.isEmpty(locDetails)
    ) {
      console.log("<marketSingleOrder> loading loc details");
      getLockerDetails();
    }
  }, [locDetails]);

  useEffect(() => {
    //placeholder
    runHolder("image-class-name");
  }, []);

  if (!topic || !selMktOrder || !creator) {
    console.log("err! missing var 3", topic, selMktOrder, creator);
    return <></>;
  }

  const showDynamicLink = async () => {
    if (selMktOrder.orderDLink && selMktOrder.orderDLink.length > 0) {
      console.log(`***selMktOrder.orderDLink`, selMktOrder.orderDLink);
      setIsDLinkModalShown(true);
    } else {
      console.log(`Making new DLink`);
      const topicLinkID = selMktOrder.topicLinkID;
      const topicID = selMktOrder.topicID;
      const orderID = selMktOrder.orderID;
      const email = selMktOrder.orderEmail;
      const title = `Order #${selMktOrder.orderUID}`;
      const desc = `Order for ${topic.topicTitle}`;
      //const dLink = "123"
      const dLink = await getDynamicLink(topicLinkID, orderID, title, desc);
      console.log(`***dLink`, dLink);
      let modifiedMarketOrder = {
        orderID: orderID,
        orderDLink: dLink,
      };
      appDispatch(
        uploadMarketDLink(
          dLink,
          topicLinkID,
          topicID,
          orderID,
          email,
          selMktOrder.orderUserID,
          topic.topicCreatorID
        )
      );
      appDispatch(fetchMarketOrderSuccess(modifiedMarketOrder));
      setIsDLinkModalShown(true);
    }
  };

  if (selMktOrder) {
    const orderSpecs = selMktOrder.orderSpecs;
    const selTopicID = selMktOrder.topicID;
    if (!orderSpecs || !selTopicID) {
      console.log("err! missing var!", orderSpecs, selTopicID);
      return <LoadingScreen />;
    }
  }

  return (
    <>
      <ShowNavBar topicLinkID={topic.topicLinkID} activeKey="order" />
      <ShowDLinkModal />
      <Container className="p-4">
        <Row className={`login__title text-left mb-4 mt-4`}>
          {t("market.receiptOrderPlaced")}:{" "}
          {moment(selMktOrder.orderTS).format("LLL")}
        </Row>

        <Card
          className={`bg-light ${styles.cartContainer}`}
          key={topic.topicID}
        >
          <ListGroup variant="flush">
            <ListGroup.Item>
              <div
                className={`d-flex justify-content-between align-items-center login__subtitle text-left`}
              >
                {t("market.orderNum")}: {selMktOrder.orderUID}
                <Button
                  variant="light"
                  className="ml-2 mt-2 mb-2"
                  onClick={showDynamicLink}
                >
                  <IoCopyOutline />
                </Button>
              </div>

              <ShowTopicHeader selMktOrder={selMktOrder} topic={topic} />
            </ListGroup.Item>

            <ListGroup.Item>
              <OrderStatus selMktOrder={selMktOrder} />
            </ListGroup.Item>

            <ListGroup.Item>
              <PaymentStatus selMktOrder={selMktOrder} />
            </ListGroup.Item>

            <ListGroup.Item>
              <ProductDetails selMktOrder={selMktOrder} />
            </ListGroup.Item>

            <ListGroup.Item>
              <AdditionalMsg selMktOrder={selMktOrder} />
            </ListGroup.Item>

            <ListGroup.Item>
              <LayoutShipping
                selMktOrder={selMktOrder}
                locDetails={locDetails}
              />
            </ListGroup.Item>

            <ListGroup.Item>
              <OrderSummary selMktOrder={selMktOrder} />
            </ListGroup.Item>

            <ListGroup.Item>
              <SellerContact
                selMktOrder={selMktOrder}
                marketOptions={marketOptions}
                topic={topic}
                creator={creator}
              />
            </ListGroup.Item>

            <ListGroup.Item>
              <PaymentMethod
                selMktOrder={selMktOrder}
                marketOptions={marketOptions}
                topic={topic}
              />
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Container>
    </>
  );
};

//SECTION: ORDER STATUS
export const OrderStatus = (props: { selMktOrder: MarketOrder }) => {
  const { t, i18n } = useTranslation();
  let variant = "secondary";
  let orderStatusText = "Unconfirmed";
  const selMktOrder = props.selMktOrder;
  const orderStatus = selMktOrder.orderStatus;
  let descText = "";
  switch (+orderStatus) {
    case marketOrderStatus.SELLERCONFIRMED:
      orderStatusText = `${t("market.receiptConfirm")}`;
      descText = `${t("market.receiptOrderStatusConfirmed")}`;
      variant = "success";
      break;
    case marketOrderStatus.SELLERUNCONFIRMED:
      orderStatusText = `${t("market.receiptUnconfirm")}`;
      descText = `${t("market.receiptOrderStatusPending")}`;
      variant = "secondary";
      break;
    case marketOrderStatus.SHIPPED:
      orderStatusText = `${t("market.receiptShipped")}`;
      descText = `${t("market.receiptOrderStatusShipped")}`;
      variant = "primary";
      break;
    case marketOrderStatus.DELIVERED:
      orderStatusText = `${t("market.receiptDelivered")}`;
      descText = `${t("market.receiptOrderStatusDelivered")}`;
      variant = "primary";
      break;
    case marketOrderStatus.CANCELLED:
      orderStatusText = `${t("market.receiptCancelled")}`;
      descText = `${t("market.receiptOrderStatusCancelled")}`;
      variant = "danger";
      break;
    default:
      break;
  }

  return (
    <div className={`d-flex flex-row justify-content-between`}>
      <div className={`${styles.marketStatus} mr-2 align-self-center`}>
        {t("market.receiptOrderStatus")}:
      </div>
      <OverlayTrigger
        key="orderStatus"
        placement="left"
        overlay={<Tooltip id="orderStatus">{`${descText}`}</Tooltip>}
      >
        <Button variant={variant} className={`${styles.statusBtn}`}>
          {" "}
          {orderStatusText}
        </Button>
      </OverlayTrigger>
    </div>
  );
};
//!SECTION

//SECTION: PAYMENT STATUS
export const PaymentStatus = (props: { selMktOrder: MarketOrder }) => {
  const { t, i18n } = useTranslation();
  let paymentStatusText = "Unpaid";
  const selMktOrder = props.selMktOrder;
  let variant = "secondary";
  let descText = "";
  const paymentStatus = selMktOrder.orderPaymentStatus;
  switch (+paymentStatus) {
    case marketPaymentStatus.UNPAID:
      paymentStatusText = `${t("market.receiptUnpaid")}`;
      variant = "secondary";
      descText = `${t("market.receiptPaymentStatusUnpaid")}`;
      break;
    case marketPaymentStatus.PAID:
      descText = `${t("market.receiptPaymentStatusPaid")}`;
      paymentStatusText = `${t("market.receiptPaid")}`;
      variant = "success";
      break;
    case marketPaymentStatus.REFUNDED:
      descText = `${t("market.receiptPaymentStatusRefuneded")}`;
      paymentStatusText = `${t("market.receiptRefunded")}`;
      variant = "primary";
      break;
    case marketPaymentStatus.MARKEDASPAID:
      descText = `${t("market.receiptPaymentStatusMarkedAsPaid")}`;
      paymentStatusText = `${t("market.receiptMarkedAsPaid")}`;
      variant = "success";
      break;
    default:
      break;
  }

  return (
    <div className={`d-flex flex-row justify-content-between`}>
      <div className={`${styles.marketStatus} mr-2 align-self-center`}>
        {t("market.receiptPaymentStatus")}:{" "}
      </div>

      <OverlayTrigger
        key="paymentStatus"
        placement="left"
        overlay={<Tooltip id="paymentStatus">{`${descText}`}</Tooltip>}
      >
        <Button variant={variant} className={`${styles.statusBtn}`}>
          {paymentStatusText}
        </Button>
      </OverlayTrigger>
    </div>
  );
};
//!SECTION

const ShowTopicHeader = (props: {
  selMktOrder: MarketOrder;
  topic: TopicData;
}) => {
  const { t, i18n } = useTranslation();
  const selMktOrder = props.selMktOrder;
  const topic = props.topic;
  const cur = selMktOrder.orderCurrency;
  const productPrice = selMktOrder.orderProductPrice;
  const shipping = selMktOrder.orderShippingFee;
  let totalPrice = productPrice;
  if (shipping && shipping > 0) {
    totalPrice = productPrice + shipping;
  }

  const totString = `${t("market.orderTotal")}:` + " " + cur + " " + totalPrice;

  return (
    <Row>
      <Col
        xs={12}
        sm={12}
        md={7}
        lg={7}
        xl={7}
        className={`${styles.marketTitle}`}
      >
        <Link
          to={{
            pathname: `/topics/${topic.topicLinkID}`,
          }}
        >
          {t("market.orderTopicTitle")}: {topic.topicTitle}
        </Link>
      </Col>
      <Col
        xs={12}
        sm={12}
        md={5}
        lg={5}
        xl={5}
        className={`d-flex flex-column align-items-sm-start align-items-md-end ${styles.marketTitle}`}
      >
        {totString}
      </Col>
    </Row>
  );
};

//SECTION: PRODUCT DETAILS
const ProductDetails = (props: { selMktOrder: MarketOrder }) => {
  const selMktOrder = props.selMktOrder;

  const lightboxOptions = {
    settings: {
      lightboxTransitionSpeed: 0.1,
    },
    buttons: {
      showThumbnailsButton: false,
      showAutoplayButton: false,
      showDownloadButton: false,
    },
    thumbnails: {
      showThumbnails: true,
    },
  };

  const ShowOrderSpecs = () => {
    //NOTE: At this point we only show one topic. i.e. only allow one topic to check out each time
    const orderSpecs = selMktOrder.orderSpecs;
    const selTopicSpecs = orderSpecs;

    return (
      <Col xs={12}>
        {/*Note Component form is needed here
              https://medium.com/@jonchurch/how-to-fix-react-error-rendered-fewer-hooks-than-expected-e6a378985d3c#:~:text=First%2C%20double%20check%20that%20you,fewer%20hooks%20than%20expected%20error. */}
        <SRLWrapper options={lightboxOptions}>
          <ShowTopicContent selTopicSpecs={selTopicSpecs} />
        </SRLWrapper>
      </Col>
    );
  };
  //!SECTION
  // NOTE[epic=snippet] an example of mapping in component form
  const ShowTopicContent = (props: {
    selTopicSpecs: MarketOrderSpec[];
  }): JSX.Element => {
    return (
      <>
        {props.selTopicSpecs.map((spec) => {
          //const market = makeSelectMarket(spec.specMktID);

          //return <ShowSpec selSpec={spec} market={market} />;
          return <ShowSpecCheckout selSpec={spec} key={spec.specID} />;
        })}
      </>
    );
  };

  const ShowSpecCheckout = (props: { selSpec: MarketOrderSpec }) => {
    const { t } = useTranslation();
    const selSpec = props.selSpec;
    const ShowQuantity = () => {
      return (
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={`d-flex flex-column justify-content-between`}
        >
          <Row
            className={`d-flex flex-column align-items-sm-start align-items-md-end ${styles.cartSpecQuanChkout}`}
          >
            {t("market.quantity")}: {selSpec.specQuantity}
          </Row>
          <Row
            className={`mt-2 d-flex flex-column align-items-sm-start align-items-md-end ${styles.cartSpecPrice}`}
          >
            {t("market.subtotal")}: {selSpec.specCurrency}{" "}
            {selSpec.specPrice * selSpec.specQuantity}
          </Row>
        </Col>
      );
    };

    const ShowSpecPhoto = () => {
      if (!selSpec.specPhoto || !selSpec.specPhoto.photoContentURL) {
        return (
          <Image
            src="holder.js/200x200?text=Empty"
            className={`p-2 ml-2 align-center bg-white w-100`}
            alt="Photo Not Found"
          />
        );
      } else {
        return (
          <Image
            src={selSpec.specPhoto.photoContentURL}
            className={`p-2 ml-2 align-center bg-white w-100`}
          />
        );
      }
    };

    return (
      <Row xs={12} sm={12} md={12} lg={12} xl={12} className={`w-100`}>
        <Col xs={3} sm={3} md={3} lg={3} xl={3} className={`p-1`}>
          <ShowSpecPhoto />
        </Col>
        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
          <Row className="h-100">
            <Col xs={12} sm={12} md={7} lg={7} xl={7}>
              <Row className={`${styles.cartSpecTitle}`}>
                <Link
                  to={{
                    pathname: `/m/${selMktOrder.topicLinkID}/${selSpec.specMktID}`,
                  }}
                >
                  {selSpec.specMktTitle ?? "No Title"}
                </Link>
              </Row>
              <Row className={`${styles.cartSpecDesc}`}>{selSpec.specDesc}</Row>
              <Row className={`mt-2 ${styles.cartSpecUnitPrice}`}>
                {selSpec.specCurrency} {selSpec.specPrice}
              </Row>
            </Col>

            {/* InputNumber for quantity */}
            {ShowQuantity()}
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row>
        <ShowOrderSpecs />
      </Row>
    </>
  );
};
//!SECTION

//SECTION: ADDITIONAL MSG
const AdditionalMsg = (props: { selMktOrder: MarketOrder }) => {
  const { t } = useTranslation();
  const selMktOrder = props.selMktOrder;
  let addMsg = <></>;
  if (selMktOrder.orderAdditionalMsg && selMktOrder.orderAdditionalMsg !== "") {
    ``;
    return (
      <>
        <div className={`${styles.marketTitle}`}>
          {t("market.receiptAdditionalMsg")}:
        </div>
        <div className={` ${styles.cartSpecQuanChkout}`}>
          {selMktOrder.orderAdditionalMsg}{" "}
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
//!SECTION

//SECTION: SHIPPING INFO
const LayoutShipping = (props: {
  selMktOrder: MarketOrder;
  locDetails: Partial<LockerLocData>;
}) => {
  const { t, i18n } = useTranslation();
  const selMktOrder = props.selMktOrder;
  const locDetails = props.locDetails;
  if (!selMktOrder) {
    return <></>;
  }
  const shippingMethod = selMktOrder.orderDeliveryMethod;
  let deliveryTitle = `${t("market.delivery")}: `;
  let deliveryContent = "";

  switch (+shippingMethod) {
    case marketDeliveryOption.NOTSET:
      deliveryContent = `${t("market.receiptDeliveryContact")}: `;
      break;
    case marketDeliveryOption.COURIER:
      deliveryTitle = `${t("market.delivery")}: ` + `${t("market.courier")}`;
      //is EFLocker
      if (selMktOrder.orderEFLockerCode !== "") {
        const locType = locDetails.isStore
          ? `${t("market.receiptSFStore")}`
          : `${t("market.receiptSFLocker")}`;
        const popular = locDetails.isHot
          ? `${t("market.receiptHot")}`
          : `${t("market.receiptRegular")}`;

        deliveryContent = `${t("market.receiptType")}: ${locType} \n${t(
          "market.receiptCode"
        )}: ${selMktOrder.orderEFLockerCode} \n${t("market.receiptAddress")}: ${
          i18n.language == "en" ? locDetails.address : locDetails.addressCT
        } \n${t("market.receiptHoursM2S")}: ${locDetails.hourmo2st} \n${t(
          "market.receiptHoursS"
        )}: ${locDetails.hoursu} \n${t(
          "market.receiptPopularity"
        )}: ${popular}`;
      } else if (selMktOrder.orderAddress1) {
        deliveryContent = `${t("market.receiptAddress")}: ${
          selMktOrder.orderAddress1
        } \n${selMktOrder.orderAddress2 ?? ""}`;
      }

      if (
        selMktOrder.orderCourierCompany &&
        selMktOrder.orderTrackingNum &&
        selMktOrder.orderTrackingNum !== ""
      ) {
        deliveryContent =
          deliveryContent +
          `\n${t("market.receiptCourierComp")}: ${
            selMktOrder.orderCourierCompany
          } \n${t("market.receiptTracking")}: ${selMktOrder.orderTrackingNum}`;
      }
      break;
    case marketDeliveryOption.PICKUP:
      deliveryTitle = `${t("market.delivery")}: ${t("market.chkoutPickup")}`;
      deliveryContent = `${selMktOrder.orderPickupDetails}`;
      break;
    case marketDeliveryOption.BOTH:
      console.log("<marketSingleOrder> order delivery option shouldn't exist!");
      deliveryTitle = "Please contact seller for delivery";
      break;
    default:
      break;
  }

  return (
    <>
      <div className={`${styles.marketTitle}`}>{deliveryTitle}</div>
      <div className={`${styles.cartSpecQuanChkout}`}>{deliveryContent}</div>
    </>
  );
};
//!SECTION

//SECTION: SUMMARY
const OrderSummary = (props: { selMktOrder: MarketOrder }) => {
  const { t } = useTranslation();
  const selMktOrder = props.selMktOrder;
  if (!selMktOrder) {
    return <></>;
  }
  const cur = selMktOrder.orderCurrency;
  const totProductPrice = selMktOrder.orderProductPrice;
  const productTot =
    `${t("market.chkoutPrdTotal")}: ` + cur + " " + totProductPrice;
  let shippingPrice = 0;
  const marketShippingFeeText = selMktOrder.orderCourierPayment;
  const shipping =
    `${t("market.chkoutShipTotal")}: ` + cur + " " + marketShippingFeeText;
  const finalPrice = selMktOrder.orderFinalPrice;

  let finalTotal = `${t("market.chkoutPrdFinal")}: ` + cur + " " + finalPrice;

  let shippingStatement = <></>;
  if (marketShippingFeeText !== "") {
    shippingStatement = (
      <div className={`${styles.cartSpecQuanChkout}`}>{shipping}</div>
    );
  }

  return (
    <>
      <div className={`mt-2 ${styles.cartSpecTotPrice}`}>{finalTotal}</div>
      <div className={`${styles.cartSpecQuanChkout}`}>{productTot}</div>
      {shippingStatement}
    </>
  );
};
//!SECTION

//SECTION: SELLER CONTACT
const SellerContact = (props: {
  selMktOrder: MarketOrder;
  marketOptions: Partial<MarketOptions>;
  topic: TopicData;
  creator: UserData;
}) => {
  const { t } = useTranslation();
  const selMktOrder = props.selMktOrder;
  const topic = props.topic;
  const sellerID = topic.topicCreatorID;
  const creator = props.creator;
  const marketOptions = props.marketOptions;
  const ShowContactMethods = () => {
    showSellerDLink(selMktOrder);
    let buffer = [] as JSX.Element[];
    console.log("<SellerContact>", selMktOrder);
    if (selMktOrder.orderSellerDLink) {
      const appLink = (
        <Row className="d-flex justify-content-center mb-5">
          <Col
            xs={12}
            className={`d-flex flex-row justify-content-center ${styles.contactMethodTitle}`}
          >
            Kalink
          </Col>
          <Col
            xs={12}
            className={`mt-2 mb-2 d-flex flex-row justify-content-center`}
          >
            <QRCode value={selMktOrder.orderSellerDLink} />
          </Col>
          <Col xs={12} className={`d-flex flex-row justify-content-center`}>
            <Link
              to={{ pathname: selMktOrder.orderSellerDLink }}
              target="_blank"
            >
              {selMktOrder.orderSellerDLink}
            </Link>
          </Col>
        </Row>
      );
      buffer.push(appLink);
    }
    if (
      marketOptions &&
      marketOptions.mktContacts &&
      marketOptions.mktContacts.length > 0
    ) {
      marketOptions.mktContacts.map((contact) => {
        const ele = (
          <Row className="d-flex justify-content-center mb-5">
            <Col
              xs={12}
              className={`d-flex flex-row justify-content-center ${styles.contactMethodTitle}`}
            >
              {contact.contactTitle}
            </Col>
            <ShowContactPhoto contact={contact} />

            <Col xs={12} className={`d-flex flex-row justify-content-center`}>
              <div>abc</div>
              <Link to={{ pathname: contact.contactLink }} target="_blank">
                {contact.contactLink}
              </Link>
            </Col>
          </Row>
        );
        buffer.push(ele);
      });
    }

    return buffer;
  };

  return (
    <>
      <div className={`${styles.marketTitle}`}>
        {t("market.receiptSellerContact")}:
      </div>
      <Row>
        <Accordion className="w-100" defaultActiveKey="0">
          <Card className={`border-0 ${styles.shipCard}`}>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              <Row>
                <Col xs={3} sm={2} md={2} lg={1} xl={1}>
                  <Image src={topic.topicCreatorAvatar} roundedCircle />
                </Col>
                <Col
                  xs={9}
                  sm={10}
                  md={10}
                  lg={11}
                  xl={11}
                  className={`d-flex flex-row justify-content-start align-items-center ${styles.contactName}`}
                >
                  {creator?.name ?? ""}
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Row className={`m-2 ${styles.contactName}`}>
                  {marketOptions?.mktContactDetails ?? ""}
                </Row>
                {ShowContactMethods()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Row>
    </>
  );
};
//!SECTION

const ShowContactPhoto = (props: { contact: MarketContactMethod }) => {
  const contact = props.contact;
  if (contact.contactPhoto.photoContentURL) {
    return (
      <Col xs={12} sm={12} md={5} lg={5} xl={5}>
        <Image src={contact.contactPhoto.photoContentURL} thumbnail />
      </Col>
    );
  } else {
    return <></>;
  }
};

const ShowPaymentPhoto = (props: { payment: MarketPaymentMethod }) => {
  const payment = props.payment;
  if (payment.paymentPhoto.photoContentURL) {
    return (
      <Col xs={12} sm={12} md={5} lg={5} xl={5}>
        <Image src={payment.paymentPhoto.photoContentURL} thumbnail />
      </Col>
    );
  } else {
    return <></>;
  }
};

const showSellerDLink = async (selMktOrder: MarketOrder) => {
  const appDispatch = useAppDispatch();
  if (selMktOrder.orderSellerDLink && selMktOrder.orderSellerDLink.length > 0) {
    console.log(
      `<marketSingleOrder> orderSellerDLink`,
      selMktOrder.orderSellerDLink
    );
  } else if (selMktOrder) {
    appDispatch(fetchSellerDLink(selMktOrder));
  }
};

//SECTION: PAYMENT
const PaymentMethod = (props: {
  selMktOrder: MarketOrder;
  marketOptions: Partial<MarketOptions>;
  topic: TopicData;
}) => {
  const { t } = useTranslation();
  const selMktOrder = props.selMktOrder;
  const marketOptions = props.marketOptions;
  const topic = props.topic;
  const orderID = selMktOrder.orderID;
  if (!marketOptions) {
    return <></>;
  }

  const payments = marketOptions.mktPayments;

  console.log(`***payments`, payments);
  const ShowPaymentReceipt = () => {
    if (selMktOrder.orderPaymentStatus == marketPaymentStatus.PAID) {
      return (
        <>
          <Row className="d-flex justify-content-center mb-5">
            <Col xs={6} className={`${styles.cartSpecTotPrice}`}>
              {selMktOrder.orderPaymentMethod.paymentTitle}
              <Image
                src={selMktOrder.orderPaymentReceiptPhoto.photoContentURL}
                thumbnail
              />
            </Col>
          </Row>
        </>
      );
    } else {
      return <></>;
    }
  };

  const ShowDropZone = (payment) => {
    if (selMktOrder.orderPaymentStatus !== marketPaymentStatus.PAID) {
      return (
        <StyledDropzone
          paymentID={payment.paymentID}
          payment={payment}
          topicID={topic.topicID}
          orderID={orderID}
        />
      );
    } else {
      return <></>;
    }
  };

  const ShowPaymentMethods = () => {
    if (payments && payments.length > 0) {
      return payments.map((payment) => {
        console.log(`<marketSingleOrder> payment`, payment);
        return (
          <Accordion
            defaultActiveKey="0"
            className="w-100"
            key={`${payment.paymentID}`}
          >
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              <Row>
                <Col xs={12} className={`${styles.contactMethodTitle}`}>
                  {payment.paymentTitle}
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Row className="d-flex justify-content-center mb-5">
                <ShowPaymentPhoto payment={payment} />
                <Col
                  xs={12}
                  className={`d-flex flex-row justify-content-center`}
                >
                  <Link to={{ pathname: payment.paymentLink }} target="_blank">
                    {payment.paymentLink}
                  </Link>
                </Col>
                {ShowDropZone(payment)}
              </Row>
            </Accordion.Collapse>
          </Accordion>
        );
      });
    }
  };

  if (!payments || payments.length === 0) {
    console.log("<marketSingleOrder> no payment methods found!");
    return (
      <>
        <div className={`${styles.marketTitle}`}>{t("market.payment")}:</div>
        <div className={`${styles.cartSpecQuanChkout}`}>
          {t("market.receiptPaymentContact")}
        </div>
      </>
    );
  }

  if (selMktOrder.orderPaymentStatus == marketPaymentStatus.PAID) {
    //Paid already, show payment info
    return (
      <>
        <div className={`${styles.marketTitle}`}>{t("market.payment")}</div>
        <Row>
          <Col xs={12} className={`${styles.cartSpecQuanChkout}`}>
            {t("market.receiptSellerMsg")}: {marketOptions.mktPaymentDetails}
          </Col>
          <Col xs={12} className={`mt-2 ${styles.marketTitle}`}>
            {ShowPaymentReceipt()}
          </Col>
        </Row>
      </>
    );
  }

  if (marketOptions) {
    return (
      <>
        <div className={`${styles.marketTitle}`}>{t("market.payment")}</div>
        <Row>
          <Accordion className="w-100" defaultActiveKey="0">
            <Card className={`border-0 ${styles.shipCard}`}>
              <Card.Body>
                <Row className={`m-2 ${styles.contactName}`}>
                  {marketOptions.mktPaymentDetails}
                </Row>

                {ShowPaymentMethods()}
              </Card.Body>
            </Card>
          </Accordion>
        </Row>
      </>
    );
  }

  return <></>;
};
//!SECTION

export default MarketSingleOrder;

// const getUploadProgress = () => (
//   dispatch: AppDispatch,
//   getState: AppGetState
// ) => {
//   const state = getState();
//   const progress = state.marketOrders.progress;
//   console.log("***progress is", progress);
// };
