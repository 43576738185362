import { UserData, AuthState } from "src/types/typings";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initState = {};
const authReducer = (state = initState, action) => {
  return state;
};

const authInitialState: AuthState = {
  authUser: {},
  isLoggedIn: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    loginStatusChanged(state, action: PayloadAction<Partial<UserData>>) {
      const payload = action.payload;

      if (payload && payload.userID && payload.userID.length > 0) {
        //state.authUser = mapAuthUser(payload);
        state.isLoggedIn = true;
        state.authUser = payload;
        console.log("store updated, user logged in!");
      } else {
        state.authUser = {};
        state.isLoggedIn = false;
        console.log("store updated, user signed out!");
      }
    },
  },
});

export const mapAuthUser = (fbUser: firebase.default.User) => {
  const authUser: Partial<UserData> = {
    userID: fbUser.uid,
    name: fbUser.displayName || "",
    profilePicLink: fbUser.photoURL || "",
  };
  return authUser;
};

export const { loginStatusChanged } = authSlice.actions;

export default authSlice.reducer;
