/**
|--------------------------------------------------
| src/redux/reducers/marketSpecSlice
|--------------------------------------------------
*/

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MarketSpec, MarketSpecState, PhotoData, MarketData } from "src/types/typings";

const marketSpecsInitialState: MarketSpecState = {
  data: {},
  isLoading: false,
  currentRequestId: undefined,
  error: null,
};

export const marketSpecSlice = createSlice({
  name: "marketSpec",
  initialState: marketSpecsInitialState,
  reducers: {
    fetchMarketSpecSuccess(state, action: PayloadAction<Partial<MarketSpec>>) {
      const payload = action.payload;
      const specID = payload.specID as string;
      state.data[specID] = Object.assign({}, state.data[specID], payload);
      console.log("updaing spec for specID", specID, payload.orderQuantity);
    },
  },
});

export const mapMarketSpec = (
  specID: string,
  topicID: string,
  marketID: string,
  val: Record<string, any>
) => {
  console.log("<marketSpecSlice> mapped market spec begin")

  const marketSpec: Partial<MarketSpec> = {
    specID: specID,
    specDesc: val.specDesc,
    specPrice: val.specPrice,
    specOriPrice: val.specOriPrice,
    specTopicID: topicID,
    specStock: val.specStock,
    specMarketID: marketID,
  };

  

  if (val.specT2) {
    const specT2IDs = Object.keys(val.specT2);
    marketSpec.specT2IDs = specT2IDs
  }

  //Make sure there are photoData
  const photoData = val.specPhoto;
  let photoIDs: string[] = []
  if (photoData) {
    photoIDs = Object.keys(photoData);
    const photoID = photoIDs[0]; //There's one photo per spec for now
    const photoDetails = photoData[photoID];
    const specPhoto = mapSpecPhoto(photoID, marketID, specID, photoDetails);
    marketSpec.specPhoto = specPhoto
  }

  console.log("<marketSpecSlice> mapped market spec", marketSpec)
  return marketSpec;
};

const mapSpecPhoto = (
  photoID: string,
  marketID: string,
  specID: string,
  val: Record<string, any>
) => {
  const photo: Partial<PhotoData> = {
    photoID: photoID,
    photoCreationTimestamp: val.photoCreationTimestamp,
    photoCreatorID: val.photoCreatorID,
    photoContentURL: val.photoUrl,
    photoMarketID: marketID,
    photoSpecID: specID,
  };
  return photo;
};

export const { fetchMarketSpecSuccess } = marketSpecSlice.actions;
export default marketSpecSlice.reducer;
