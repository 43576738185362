/**
|--------------------------------------------------
| src/redux/reducers/marketOrderSlice
|--------------------------------------------------
*/

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MarketOrder, MarketOrderState } from "src/types/typings";

const marketOrdersInitialState: MarketOrderState = {
  data: {},
  isLoading: false,
  currentRequestId: undefined,
  error: null,
  progress: {},
};

export const marketOrderSlice = createSlice({
  name: "marketOrder",
  initialState: marketOrdersInitialState,
  reducers: {
    fetchMarketOrderSuccess(
      state,
      action: PayloadAction<Partial<MarketOrder>>
    ) {
      const payload = action.payload;
      const orderID = payload.orderID as string;
      state.data[orderID] = Object.assign({}, state.data[orderID], payload);
      console.log("fetchMarketOrderSuccess", orderID, payload);
    },
    uploadMarketReceiptProgress(
      state,
      action: PayloadAction<{ paymentID: string; pct: number }>
    ) {
      const payload = action.payload;
      const paymentID = payload.paymentID;
      const pct = payload.pct;

      let newProg = {};
      newProg[paymentID] = pct;
      state.progress = Object.assign({}, state.progress, newProg);
      console.log(`progress A2 is`, state.progress);
    },
  },
});

export const {
  fetchMarketOrderSuccess,
  uploadMarketReceiptProgress,
} = marketOrderSlice.actions;
export default marketOrderSlice.reducer;
