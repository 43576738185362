/**
|--------------------------------------------------
| src/redux/selectors/topicSelector
|--------------------------------------------------
*/

import { createSelector } from "reselect";
import { AppState } from "src/redux/store";
import _ from "lodash";
import { TopicData } from "src/types/typings";

export const selectTopic = createSelector(
  (state: AppState) => state.topics.data,
  (_, topicLinkID: string) => topicLinkID,
  (topics, topicLinkID) => {
    console.log("selector topicLinkID is", topicLinkID);

    const topicArr = Object.values(topics);
    const filtered = topicArr.filter((topic) => {
      if (topic.topicLinkID === topicLinkID) {
        console.log(
          "inside filter",
          topic.topicID,
          topic.topicLinkID,
          topicLinkID
        );
        return topic;
      }
    });
    console.log("filtered value", filtered.length);
    if (filtered.length === 1) {
      const res = filtered[0];
      console.log(
        "selectedTopic at selector",
        res.topicID,
        res.topicLinkID,
        topicLinkID
      );
      return res;
    } else {
      console.log("did not select topic at selector");
    }

    // var filtered = _.pickBy(topics, function (topic) {
    //   return topic.topicLinkID === topicLinkID;
    // }) as Partial<TopicData>;

    // console.log("selector result", filtered);

    // return filtered;
    //return topics[topicLinkID];
  }
);

export const selectTopicWithTopicID = createSelector(
  (state: AppState) => state.topics.data,
  (_, topicID: string) => topicID,
  (topics, topicID) => {
    return topics[topicID];
  }
);
