/**
|--------------------------------------------------
| src/redux/userSlice
|--------------------------------------------------
*/

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserData, UserState } from "src/types/typings";
import { fetchUserCredentials } from "src/firebase/firebaseUsers";
import { AppDispatch, AppState, AppGetState } from "src/redux/store";
import _ from "lodash";
import { cleanPhotoLink } from "src/firebase/firebaseGlobal";
import { unwrapResult } from "@reduxjs/toolkit";

const usersInitialState: UserState = {
  data: {},
  isLoading: false,
  currentRequestId: undefined,
  error: null,
};

export const fetchUserCredentialsThunk = createAsyncThunk<
  Partial<UserData>,
  string,
  {
    state: AppState;
    requestId: string;
  }
>(
  "users/fetchUserCredentials",
  async (userID: string, { getState, requestId }) => {
    const curState = getState();
    const userData = await fetchUserCredentials(userID);

    //Special care for gs://
    let profilePicLink = userData.profilePicLink ?? "";
    const cleanProfilePicLink = await cleanPhotoLink(profilePicLink);
    let backgroundCoverLink = userData.backgroundCoverLink ?? "";
    const cleanBackgoundCoverLink = await cleanPhotoLink(backgroundCoverLink);

    const user: Partial<UserData> = {
      name: userData.name ?? "no name",
      email: userData.email ?? "no email",
      backgroundCoverLink: cleanBackgoundCoverLink,
      profilePicLink: cleanProfilePicLink,
      userID: userID,
    };
    return user as Partial<UserData>;
  }
);

export const userSlice = createSlice({
  name: "users",
  initialState: usersInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserCredentialsThunk.pending, (state, action) => {
      state.isLoading = true;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(fetchUserCredentialsThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      const userID = payload.userID as string;
      if (payload && !_.isEmpty(payload)) {
        state.data[userID] = Object.assign({}, state.data[userID], payload);
        state.isLoading = false;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchUserCredentialsThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.currentRequestId = undefined;
    });
  },
});

export default userSlice.reducer;

export const fetchOnePerson = (userID: string) => async (
  dispatch: AppDispatch,
  getState: AppGetState
) => {
  let user = {} as Partial<UserData>;
  try {
    const personData = getState().users.data;
    const userInfo = personData[userID];
    if (userInfo) {
      user = userInfo;
      console.log("have user already", user);
    } else {
      console.log("userID is", userID);
      const resultAction = await dispatch(fetchUserCredentialsThunk(userID));
      console.log("resultAction is", resultAction);
      user = unwrapResult(resultAction);
      console.log("user is", user);
    }
    return user;
  } catch (err) {
    console.log("error at fetchOnePerson", err);
    return user;
  }
};

// export const getSelfUser = async () => {
//   const fbUser = auth().currentUser;
//   if (fbUser) {
//     const userID = fbUser.uid;
//     const user = await fetchOnePerson(userID);
//     return user;
//   } else {
//     return null;
//   }
// };
