import topicReducer from "./topicSlice";
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import groupSliceReducer from "src/redux/reducers/groupSlice";
import authSliceReducer from "src/redux/reducers/authSlice";
import userSliceReducer from "src/redux/reducers/userSlice";
import topicSliceReducer from "src/redux/reducers/topicSlice";
import marketSliceReducer from "src/redux/reducers/marketSlice";
import photoSliceReducer from "src/redux/reducers/photoSlice";
import marketSpecSliceReducer from "src/redux/reducers/marketSpecSlice";
import marketOrderSliceReducer from "src/redux/reducers/marketOrderSlice";

// Combine all the reducers
const rootReducer = combineReducers({
  auth: authSliceReducer,
  users: userSliceReducer,
  groups: groupSliceReducer,
  topics: topicSliceReducer,
  markets: marketSliceReducer,
  photos: photoSliceReducer,
  marketSpecs: marketSpecSliceReducer,
  marketOrders: marketOrderSliceReducer,
  //messages: msgSliceReducer,
  //notifications: notificationSliceReducer,
  //photos: photoSliceReducer,
  //firebase: firebaseReducer,
});

// const rootReducer = combineReducers({
//   auth: authReducer,
//   topic: topicReducer,
//   firebase: firebaseReducer,
// });

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
