import axios from "axios";
import { firebaseConfig } from "src/config/fbConfig";

export const getDynamicLink = async (topicLinkID: string, orderID: string, title: string, desc: string) => {
  // const axiosInstance = axios.create({
  //   baseURL: "/api/",
  //   timeout: 2000,
  //   headers: { "X-Custom-Header": "foobar" },
  // });

  const apiKey = firebaseConfig.apiKey;


  const oriLink = `https://kalinkapp.com/mr?id1=${topicLinkID}&id2=${orderID}`
  const info = {
    dynamicLinkInfo: {
      domainUriPrefix: "https://kalink.page.link",
      link: oriLink,
      androidInfo: {
        androidPackageName: "com.kalinkapp.kalink",
        androidFallbackLink: oriLink
      },
      iosInfo: {
        iosBundleId: "com.kalinkapp.kalink",
        iosFallbackLink: oriLink
      },
      socialMetaTagInfo: {
        socialTitle: title,
        socialDescription: desc
      },
    },
    suffix: {
      option: "UNGUESSABLE",
    },
  };

  //socialImageLink: string

  try {
    const dLink = await axios
    .post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
      info
    )
    console.log(`DLINK is`, dLink.data.shortLink)
    return dLink.data.shortLink
  } catch (err) {
    console.log(err.response)
  }
 
    // .then((res) => {
    //   console.log(res);
    //   console.log(res.data);
    // })
    // .catch((error) => {
    //   console.log(error.response);
    // });
};
