//import { firebase } from '../../config/fbConfig';
import firebase from "firebase/app";
import "firebase/database";

import { AppDispatch, AppState, AppGetState } from "src/redux/store";
//import storage from "@react-native-firebase/storage";
import _ from "lodash";
import { fetchTopicSuccess } from "src/redux/reducers/topicSlice";
import { fetchGroupCredentialsThunk } from "src/redux/reducers/groupSlice";
import { UserData, TopicData, GroupData } from "src/types/typings";

import { useAppDispatch } from "src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
//import { fetchUserCredentialsThunk } from "src/redux/reducers/userSlice";
import { fetchSingleTopicDetails } from "src/firebase/firebaseTopics";
import { cleanPhotoLink } from "src/firebase/firebaseGlobal";

//Not yet implemented
export const createTopic = (topic) => {
  return (dispatch, getState) => {
    //make async call to database
    dispatch({ type: "CREATE_TOPIC", topic });
  };
};

export function fetchTopicThunk(topic) {
  return async function (dispatch) {
    function onSuccess(success) {
      console.log("topicID4", success.id);
      dispatch({ type: "FETCH_TOPIC", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "FETCH_TOPIC_ERROR", err: error });
      return error;
    }
    try {
      const topic0 = await getTopicID(topic);
      const topic1 = await getTopicData(topic0);
      const success = await getUserData(topic1);
      //dispatch({ type: 'FETCH_TOPIC', payload: success });
      await onSuccess(success);
    } catch (error) {
      console.log("thunk error", error);
      onError(error);
    }
  };
}

function getTopicID(topic) {
  const defer = new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`/topicLink/${topic.id}`)
      .once(
        "value",
        (snap) => {
          const topicID = snap.val();
          console.log("topicID 1", topic.id);
          console.log("topicID found", topicID);
          topic.id = topicID;
          resolve(topic);
        },
        (err) => {
          console.log("error on topic1", err);
          reject(err);
        }
      );
  });
  return defer;
}

function getTopicData(topic) {
  const defer = new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`/topics/${topic.id}`)
      .once(
        "value",
        (snap) => {
          const content = snap.val().topicContent;
          const creatorName = snap.val().topicCreatorName;
          const topicTitle = snap.val().topicTitle;
          const topicHeaderPath = snap.val().topicHeaderPath;
          const topicCreatorID = snap.val().topicCreatorID;
          const topicCreationTimestamp = snap.val().topicTimestamp;
          const topicModifyTimestamp = snap.val().topicModifyTimestamp;
          const topicTimestamp = !topicModifyTimestamp
            ? topicCreationTimestamp
            : topicModifyTimestamp;

          topic.topicCreatorName = creatorName;
          topic.topicTitle = topicTitle;
          topic.topicHeaderPath = topicHeaderPath;
          topic.topicCreatorID = topicCreatorID;
          topic.topicTimestamp = topicTimestamp;
          topic.topicContent = content;

          resolve(topic);
        },
        (err) => {
          console.log("error on topic1", err);
          reject(err);
        }
      );
  });
  return defer;
}

function getUserData(topic2) {
  //if (topic2.topicCreatorID) {
  const defer = new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`/users/${topic2.topicCreatorID}/credentials/profilePicLink`)
      .once(
        "value",
        (snap2) => {
          const profilePicPath = snap2.val();
          topic2.userProfilePicPath = profilePicPath;
          resolve(topic2);
        },
        (err) => {
          reject(err);
        }
      );
  });
  return defer;
}

//! New Stuff
//Search if the group is arleady in redux, if not, download the credentials
export const fetchTopicGroups = (groupIDs: string[]) => async (
  dispatch: AppDispatch,
  getState: AppGetState
) => {
  let promises: Partial<GroupData>[] = [];
  await Promise.all(
    groupIDs.map(async (groupID) => {
      const groupDataPre = getState().groups.data;
      const groupInfo = groupDataPre[groupID];
      if (groupInfo) {
        //Group is found in redux already, no need to refetch
        console.log(
          `fetchTopicGroups group ${groupID} found, pushing directly`
        );
        promises.push(groupInfo);
      } else {
        console.log(
          `fetchTopicGroups group ${groupID} not found, need to fetch`
        );
        const groupData = await dispatch(fetchOneGroup(groupID));
        promises.push(groupData);
      }
    })
  );
  return Promise.all(promises);
};

const fetchOneGroup = (groupID: string) => async (dispatch: AppDispatch) => {
  try {
    console.log("fetching at fetchOneGroup");
    const resultAction = await dispatch(fetchGroupCredentialsThunk(groupID));
    const group = unwrapResult(resultAction);
    console.log("fetchOneGroup result", group);
    return group;
  } catch (err) {
    //err
    console.log("error at fetchOneGroup");
    return {};
  }
};

export const storeTopicDetailsToRedux = (snap = [] as Record<string, any>) => (
  dispatch: AppDispatch
) => {
  const val = snap.val();
  console.log("<storeTopicDetailsToRedux> val s", val)
  const topicID = snap.key;

  const topic: Partial<TopicData> = {
    topicID: topicID,
    topicContent: val.topicContent,
    topicTitle: val.topicTitle,
    topicHeaderPath: val.topicHeaderPath,
    topicCreatorID: val.topicCreatorID,
    topicCreatorName: val.topicCreatorName,
    topicRichContent: val.topicRichContent,
    topicCreationTimestamp: val.topicTimestamp,
    topicModifyTimestamp: val.topicModifyTimestamp,
    topicLastMessage: val.topicLastMessage,
    topicLastTimestamp: val.topicLastTimestamp,
    topicPhotosCount: val.topicPhotosCount,
    topicVideosCount: val.topicVideosCount,
    topicGroups: val.topicGroups,
    topicMarketsCount: val.topicMarketsCount,
    topicNeedsDownload: false, //Store it as false so it won't get refetched
  };

  console.log("storeTopicDetailsToRedux finished storing details", topic);
  dispatch(fetchTopicSuccess(topic));
};

//Takes in the new topic related data and output a topic object
export const prepareTopicData = async (
  groups: Partial<GroupData>[],
  user: Partial<UserData>,
  topicData: Record<string, any>,
  topic: Partial<TopicData>
) => {
  //let topic = state.topics[topicID];

  const val = topicData.val();
  topic.topicDM = Object.is(val.topicDM, undefined) ? false : val.topicDM;
  topic.topicDMRecipientID = val.topicDMRecipientID;

  let groupName = "";
  const groupAvatars: string[] = [];
  let strURL = "";

  if (!topic.topicDM) {
    groups.forEach((group) => {
      groupName = groupName + group.groupName + ", ";
      groupAvatars.push(group.groupAvatar ?? ""); //Use default avatar?
    });
    groupName = groupName.replace(/,\s*$/, "");
    const groupAvatar = groupAvatars[0];
    strURL = groupAvatar;
  } else {
    //isDM

    groupName = user.name ?? "";
    strURL = user.profilePicLink ?? "";
  }

  //Special care for gs://
  const cleanStrURL = await cleanPhotoLink(strURL);

  topic.topicGroupNames = groupName;
  //TODO: should be multiple avatars //May have problem

  if (!topic.topicAvatars) {
    topic.topicAvatars = [];
  }
  topic.topicAvatars.push(cleanStrURL);
  topic.topicCreatorAvatar = user.profilePicLink; //This is for web single page display
  return topic;
};

// export const fetchTopic = (topic) => {
//     return (dispatch) => {
//         const id = topic.id;
//         database.ref(`topics/${id}/topicContent`).once('value', snap => {
//             let content = snap.first.val();
//             topic.content = content
//         })
//         .then(() => dispatch({ type: 'FETCH_TOPIC', topic }))
//     }
// }
