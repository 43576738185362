import firebase from "firebase/app";
//import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'
//kalink-3b0d7.firebaseapp.com
//for authDoamin refer to: https://stackoverflow.com/questions/41736460/exception-this-browser-is-not-supported-or-3rd-party-cookies-and-data-may-be-di
let firebaseConfig = {
    apiKey: "AIzaSyDtevOMDo5MsSj9YQaxnMu-6f2QenPX2sI",
    authDomain: "auth.kalinkapp.com",
    databaseURL: "https://kalink-3b0d7.firebaseio.com",
    projectId: "kalink-3b0d7",
    storageBucket: "kalink-3b0d7.appspot.com",
    messagingSenderId: "691788242859",
    appId: "1:691788242859:web:b048b3e11fdaf373"
  };

// if (window.location.hostname === 'localhost') {
//   firebaseConfig = {
//     host: "losthost:5000",
//     databaseURL: 'http://localhost:9000?ns=kalink-3b0d7'
//   }
// }


const app = firebase.initializeApp(firebaseConfig);

//uncomment for emulator
if (window.location.hostname === "localhost") {
  firebase.functions().useFunctionsEmulator("http://localhost:5001");
  //app.auth().useEmulator("http://localhost:9099");
  firebase.database().useEmulator("localhost", 9000)
  firebase.storage().useEmulator("localhost", 9199)
}



//export const topicsRef = database.child("topics");
export default app;
export const database = firebase.database()
export const storage = firebase.storage()
export { firebaseConfig }
