import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import "./App.scss";
import "./i18n";
import SimpleReactLightbox from 'simple-react-lightbox'

import { Provider } from "react-redux";
import store from "./redux/store";

//import { reactReduxFirebase } from 'react-redux-firebase'
import fbConfig from "./config/fbConfig";

// const store = createStore(rootReducer,
//     compose(
//         applyMiddleware(thunk)
//     )
// );

//reactReduxFirebase(fbConfig)

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <App />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
