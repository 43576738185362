/**
|--------------------------------------------------
| src/firebase/firebaseGlobal
|--------------------------------------------------
*/
import { storage } from "src/config/fbConfig";

export const cleanPhotoLink = async (photoURLstr: string) => {
  if (photoURLstr.startsWith("gs")) {
    const avatarPath = photoURLstr.split(".com").pop();
    const url = await storage
      .ref(avatarPath)
      .getDownloadURL()
      .catch((e) => {
        return "";
      });
    if (typeof url === "string") {
      return url;
    } else {
      return ""; //error
    }
  } else {
    return photoURLstr;
  }
};
