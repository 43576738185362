import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
import styled from "styled-components";
import { Row, Col, Image, Button } from "react-bootstrap";
import { uploadMarketReceipt } from "src/firebase/firebaseMarkets";
import { AppState, useAppDispatch } from "src/redux/store";
import { fetchMarketOrderSuccess } from "src/redux/reducers/marketOrderSlice";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const makeSelectProgress = (paymentID) => {
  const selProgress = useSelector((state) => {
    return state.marketOrders.progress[paymentID] ?? 0;
  });
  return selProgress;
};

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#cccccc";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #eeeeee;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

//SECTION: MAIN FUNCTION
export const StyledDropzone = (props) => {
  const [files, setFiles] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const paymentID = props.paymentID;
  const payment = props.payment;
  const topicID = props.topicID;
  const orderID = props.orderID;
  const appDispatch = useAppDispatch();
  const {t} = useTranslation()

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      if (!showUpload) {
        setShowUpload(true);
      }
    },
  });

  const thumbs = files.map((file) => (
    <Col xs={3} sm={4} md={5} lg={5} xl={5}>
      <Image src={file.preview} thumbnail />
    </Col>
  ));

  const onClickUpload = () => {
    //
    console.log(`***paymentID is`, paymentID);
    console.log(`topicID`, topicID);
    console.log(`orderID`, orderID);
    const file = files[0];
    if (file) {
      // const fileLink = file;
      // console.log(`fileLink`, fileLink);
      appDispatch(uploadMarketReceipt(topicID, file, orderID, payment));
      // const modifiedMarketOrder = await appDispatch(
      //   uploadMarketReceipt(topicID, file, orderID, payment)
      // );
      // console.log(`modifiedMarketOrder 2`, modifiedMarketOrder);
      // if (modifiedMarketOrder) {
      //   console.log(`***modifiedMarketOrder`, modifiedMarketOrder);
      //   appDispatch(fetchMarketOrderSuccess(modifiedMarketOrder));
      // }
    }
  };

  const onClickRemove = () => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
    setFiles([]);
    setShowUpload(false);
  };

  {
    /* <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div> */
  }

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const UploadRemoveRow = () => {
    if (showUpload) {
      return (
        <Row className="d-flex justify-content-center">
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="d-flex flex-row justify-content-center mt-4"
          >
            <Button
              variant="outline-danger"
              className="w-50"
              onClick={onClickRemove}
            >
            {t("fileDrop.remove")}
            </Button>
          </Col>

          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="d-flex flex-row justify-content-center mt-4"
          >
            <Button variant="primary" className="w-50" onClick={onClickUpload}>
            {t("fileDrop.upload")}
            </Button>
          </Col>
        </Row>
      );
    } else {
      return <></>;
    }
  };

  const ShowProgressBar = () => {
    const p = makeSelectProgress(paymentID)
    if (p == 0) {
      return <></>
    } else {
      return (
      <Col xs={12} className="mt-2">
        <ProgressBar now={p} />
      </Col>
    )
    }
  }

  return (
    <div className="container">
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <p>{t("fileDrop.dragndrop")}</p>
        <aside>
          <Row className="mt-4 d-flex flex-row justify-content-center">
            {thumbs}
            
            {ShowProgressBar()}
          </Row>
        </aside>
      </Container>
      {UploadRemoveRow()}
    </div>
  );
};
//!SECTION

//style={thumbsContainer}

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 200,
  height: 200,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
  objectFit: "contain",
};
