/**
|--------------------------------------------------
| src/firebase/firebasePhotos
|--------------------------------------------------
*/
import { database } from "src/config/fbConfig";

export const fetchSinglePhotoFromFirebase = async (
  topicID: string,
  photoID: string
) => {
  const ref = database.ref(`topicPhotos/${topicID}/${photoID}`);
  try {
    const snapshot = await ref.once("value");
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return null;
    }
  } catch (error) {
    return error;
  }
};
