import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ShowNavBar from "src/components/navBar";

const NotFound = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <ShowNavBar topicLinkID={""} isMarket={false} activeKey="home" />
      <div className="mt-4 d-flex text-secondary justify-content-center notFound__title">
        {t("notFound.title")}
      </div>
      <div className="mt-2 d-flex text-secondary justify-content-center notFound__body">
        {t("notFound.subTitle")}
      </div>
      <Link
        to="/"
        className="mt-4 d-flex justify-content-center notFound__link"
      >
        {t("notFound.goHome")}
      </Link>
    </>
  );
};
export default NotFound;
